import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursAdd = ({ toggleDialogOpen, memoDataset }) => {
  const classes = useCss(styles);
  const initFormValues = {
    hoursName: "",
    description: "",
    hoursConfig: [],
  };
  const [formValue, setFormValue] = useState(initFormValues);
  const [hoursNameError, sethoursNameError] = useState(false);

  useEffect(() => {
    memoDataset(initFormValues);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formValue, [name]: value };

    setFormValue(data);
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);

    memoDataset({ [name]: value });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formControl}
    >
      <Box>
        <SbuxTextField
          isError={hoursNameError}
          error={hoursNameError}
          label={"Hours Name"}
          handleChange={handleChange}
          value={formValue.hoursName}
          name={"hoursName"}
          required
          errorMessage={
            hoursNameError ? "Hours name required at least 1 character" : " "
          }
        />
      </Box>

      <Box>
        <SbuxTextField
          label={"Description"}
          handleChange={handleChange}
          value={formValue.description}
          name={"description"}
        />
      </Box>

      <Box className="fullWidth">
        <MainHoursConfigTable
          rowData={{}}
          toggleDialogOpen={toggleDialogOpen}
          memoDataset={memoDataset}
        />
      </Box>
    </Box>
  );
};

export default MainHoursAdd;
