import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import ClosureMsgAutoCompleteTimeTextField from "./ClosureMsgAutoCompleteTimeTextField";
import moment from "moment";

const ClosureMsgAutoCompleteTime = ({
  name,
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  startTimeOptions,
  endTimeOptions,
  timeZone,
  handleStartTimeChange,
  handleEndTimeChange,
  disabled = false,
}) => {
  const [startOptions, setStartOptions] = useState([]);
  const [endOptions, setEndOptions] = useState([]);
  const [startInputValue, setStartInputValue] = useState("");
  const [endInputValue, setEndInputValue] = useState("");
  const [startTimeError, setStartTimeError] = useState(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState("");
  const [endTimeError, setEndTimeError] = useState(false);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState("");

  useEffect(() => {
    startTimeOptions &&
      startTimeOptions.length > 0 &&
      setStartOptions(startTimeOptions);
    endTimeOptions &&
      endTimeOptions.length > 0 &&
      setEndOptions(endTimeOptions);
  }, []);

  useEffect(() => {
    startTimeValue && setStartInputValue(startTimeValue);
    endTimeValue && setEndInputValue(endTimeValue);
    if (startTimeValue !== startInputValue) {
      setEndTimeErrorMessage("");
      setEndTimeError(false);
    }
  }, [startTimeValue, endTimeValue]);

  useEffect(() => {
    if (timeZone && startTimeValue && endInputValue) {
      handleEndDateAndTimeOnChange(
        startDate,
        endDate,
        startTimeValue,
        endTimeValue
      );
    }
  }, [startDate, endDate, timeZone, endInputValue]);

  const handleEndDateAndTimeOnChange = (
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    const compareTime = moment(startTime, "hh:mm A", true);
    const newTime = moment(endTime, "hh:mm A", true);
    const sDate = moment(startDate).format("MM/DD/YYYY");
    const eDate = moment(endDate).format("MM/DD/YYYY");

    const updatedEndDateTime = `${eDate} ${endTime}`;
    const selectedTimeZoneDateTime =
      timeZone && moment.tz(moment(), timeZone).format("MM/DD/YYYY hh:mm A");
    const isValidUpdatedEndDateTime = timeZone
      ? moment(updatedEndDateTime).isAfter(
          moment(selectedTimeZoneDateTime),
          "time"
        )
      : false;
    const dateDiff = sDate === eDate ? 0 : 1;

    if (
      (newTime &&
        newTime.isValid() &&
        compareTime &&
        compareTime.isValid() &&
        isValidUpdatedEndDateTime &&
        newTime.isSameOrAfter(compareTime)) ||
      (newTime &&
        newTime.isValid() &&
        compareTime &&
        compareTime.isValid() &&
        isValidUpdatedEndDateTime &&
        dateDiff > 0)
    ) {
      setEndTimeErrorMessage("");
      setEndTimeError(false);
    } else {
      setEndTimeErrorMessage("Please enter a valid time");
      setEndTimeError(true);
    }
    setEndInputValue(endTime);
  };

  const handleStartTimeOnInputChange = (event, newValue) => {
    const compareTime = moment(endInputValue, "hh:mm A", true);
    const newTime = moment(newValue, "hh:mm A", true);
    const eDate = moment(endDate).format("MM/DD/YYYY");

    const selectedTimeZoneDateTime = timeZone && moment.tz(moment(), timeZone);
    const timeZoneEndTime = moment(selectedTimeZoneDateTime)
      .add(30, "minutes")
      .format("hh:mm A");
    if (newTime && newTime.isValid() && compareTime && compareTime.isValid()) {
      setStartTimeErrorMessage("");
      setStartTimeError(false);
    } else {
      setStartTimeErrorMessage("Please enter a valid time");
      setStartTimeError(true);
    }

    setStartInputValue(newValue);
    setEndInputValue(timeZoneEndTime);
    newTime.isValid() && handleStartTimeChange(newValue);
  };

  const handleEndTimeOnInputChange = (event, newValue) => {
    handleEndDateAndTimeOnChange(startDate, endDate, startInputValue, newValue);
    handleEndTimeChange(newValue);
  };

  return (
    <Autocomplete
      size="small"
      disableClearable
      freeSolo
      value={name === "Start Time" ? startInputValue : endInputValue}
      inputValue={name === "Start Time" ? startInputValue : endInputValue}
      onInputChange={
        name === "Start Time"
          ? handleStartTimeOnInputChange
          : handleEndTimeOnInputChange
      }
      sx={{
        "& .MuiInputBase-input": {
          height: "1rem",
        },
      }}
      disabled={disabled}
      options={
        name === "Start Time"
          ? startOptions && startOptions.map((option) => option.label)
          : endOptions && endOptions.map((option) => option.label)
      }
      renderInput={(params) => (
        <ClosureMsgAutoCompleteTimeTextField
          isError={name === "Start Time" ? startTimeError : endTimeError}
          errorMessage={
            name === "Start Time" ? startTimeErrorMessage : endTimeErrorMessage
          }
          params={params}
        />
      )}
      ListboxProps={{
        style: {
          maxHeight: 150,
          fontSize: 12,
        },
      }}
    />
  );
};

export default ClosureMsgAutoCompleteTime;
