import {
  Box,
  Paper,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import SbuxLoader from "../../../../../components/SbuxLoader";
import SbuxSelect from "../../../../../components/SbuxSelect";
import {
  getTableListData,
  getTableMetaData,
  createOrUpdateListData,
} from "../../../../../services/dataMgt";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import DataManagementRow from "./DataManagementRow";
import DataManagementDialog from "../../common/DataManagementDialog";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";
import { getInstanceIdSelector } from "../../../../../selectors/userInstanceSelector";
import {
  getMetaConfigDataSelector,
  getTableListDataSelector,
  getTableListLoadingSelector,
} from "../../../../../selectors/dataMgtSelector";

const DataManagementTabs = () => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [row, setRow] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableName, setTableName] = useState(null);
  const [tableFriendlyName, setTableFriendlyName] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const [defaultValue, setdefaultValue] = useState("");
  const [isDisableAddNewButton, setIsDisableAddNewButton] = useState(true);
  const [crudOperations, setCrudOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const instanceId = useSelector(getInstanceIdSelector);
  const metaConfigData = useSelector(getMetaConfigDataSelector);
  const tableListData = useSelector(getTableListDataSelector);
  const tableListLoading = useSelector(getTableListLoadingSelector);

  const classes = useCss(styles);
  const dispatch = useDispatch();
  const fetchListData = async (tableName) => {
    await dispatch(getTableListData(tableName));
  };

  const fetchListMetaData = async () => {
    await dispatch(getTableMetaData());
  };

  useEffect(() => {
    fetchListMetaData();
  }, [instanceId]);

  useEffect(() => {
    if (metaConfigData?.tables?.length && !tableName) {
      const tableList = metaConfigData?.tables
        .map((item) => ({
          name: item.friendlyName,
          value: item.tableName,
          uiPosition: item.uiPosition,
        }))
        .sort((a, b) => a.uiPosition - b.uiPosition);
      setFilterList(tableList);
      if (tableList.length > 0) {
        const selectedName = tableList[0].value;
        handleTableSelection(selectedName);
      }
    } else {
      setFilterList([]);
      setColumns([]);
      setTableName(null);
      setdefaultValue("");
      setTableFriendlyName(null);
    }
  }, [metaConfigData]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const handleTableSelection = (selectedTableName) => {
    let selectedItem = metaConfigData?.tables?.find(
      (item) => item.tableName === selectedTableName
    );
    if (selectedItem) {
      fetchListData(selectedItem?.tableName);
      const columnValues = selectedItem?.config?.attributes
        ?.filter((attribute) => !attribute.hideOnUi)
        ?.map((item) => {
          return {
            label: item.friendlyName,
            id: item.name,
            disabled: item.lockedForEdit,
            type: item?.type,
            uiPosition: item.uiPosition,
            required: item?.required,
            maxLength: item?.maxLength,
            partitionKey: item?.partitionKey ? item?.partitionKey : false,
            sortable: true,
          };
        })
        .sort((a, b) => a.uiPosition - b.uiPosition);
      const tempCrudOperations = selectedItem?.crudOperations;

      setCrudOperations(tempCrudOperations);
      if (tempCrudOperations.includes("CREATE")) {
        setIsDisableAddNewButton(false);
      } else {
        setIsDisableAddNewButton(true);
      }

      if (tempCrudOperations && tempCrudOperations.includes("DELETE")) {
        columnValues.push({
          label: "Action",
          id: "action",
        });
      }

      setColumns(columnValues);
      setTableName(selectedTableName);
      setdefaultValue(selectedTableName);
      setTableFriendlyName(selectedItem?.friendlyName);
    }
  };

  const handleModal = (isNew = false) => {
    setAddNew(isNew);
    setOpenDialogue((prevState) => !prevState);
  };

  const handleEditRowClick = (row) => {
    setRow(row);
    handleModal();
  };

  const handleSave = async (listDataPayload) => {
    const result = await dispatch(createOrUpdateListData(listDataPayload));
    if (result?.meta?.requestStatus === "fulfilled") {
      handleModal();
      setRow(null);
      fetchListData(listDataPayload.tableName);
    }
  };

  const handleDeleteRow = async () => {
    const attributes = [];
    for (const key in row) {
      if (row.hasOwnProperty(key)) {
        attributes.push({ name: key, value: row[key] });
      }
    }

    const listDataPayload = {
      payload: {
        attributes: attributes,
      },
      tableName: tableName,
      isDelete: true,
    };
    const result = await dispatch(createOrUpdateListData(listDataPayload));
    if (result?.meta?.requestStatus === "fulfilled") {
      setOpenDeleteDialogue(false);
      fetchListData(tableName);
    }
  };

  const handleDeleteRowClick = (row) => {
    setRow(row);
    setOpenDeleteDialogue(true);
  };

  const renderSortedColumn = () => {
    return columns
      .filter((column) => column?.id !== "action")
      .sort((x, y) => x.uiPosition - y.uiPosition);
  };

  const TableSelect = () => {
    if (!filterList) {
      return null;
    }

    return (
      <Box className={classes.dataMgtSelect}>
        <Box className={classes.resize}>
          {" "}
          <SbuxTranslate>{`Make a selection`}</SbuxTranslate>
        </Box>

        <SbuxSelect
          menuItems={filterList}
          handleSelectChange={handleTableSelection}
          value={defaultValue}
          placeHolderText={"Select a table"}
        />
      </Box>
    );
  };

  const dialogConfig = {
    title: "Delete Item",
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <>
      <Paper>
        {tableName && tableListLoading ? (
          <SbuxLoader />
        ) : tableName && tableListData && tableListData?.length > 0 ? (
          <SbuxEnhancedTable
            rows={tableListData}
            columns={columns}
            RowSource={DataManagementRow}
            showFooter
            showSearchBar
            isManager
            showAddNewButton
            handleAddNewButtonClick={() => handleModal(true)}
            isdisableAddNewButton={isDisableAddNewButton}
            addNewButtonTitle={<SbuxTranslate>{"Add New"}</SbuxTranslate>}
            handleEditRowClick={handleEditRowClick}
            handleDeleteRowClick={handleDeleteRowClick}
            handleRefrehClick={() => {}}
            showSubTabs
            SubTabs={TableSelect}
            isLoading={isLoading}
          />
        ) : null}
        {openDialogue && (
          <DataManagementDialog
            title={
              addNew ? (
                <SbuxTranslate>{`Create new ${tableFriendlyName}`}</SbuxTranslate>
              ) : (
                <SbuxTranslate>{`Edit ${tableFriendlyName}`}</SbuxTranslate>
              )
            }
            open={openDialogue}
            toggleDialogOpen={() => setOpenDialogue(false)}
            row={row}
            rows={tableListData}
            addNew={addNew}
            tableName={tableName}
            onSave={handleSave}
            columns={renderSortedColumn()}
            crudOperations={crudOperations}
          />
        )}

        {openDeleteDialogue && (
          <SbuxDialog2 open={openDeleteDialogue} config={dialogConfig}>
            <DialogContent dividers>
              <Typography variant="h5">
                <SbuxTranslate>
                  {"Are you sure you want to delete it?"}
                </SbuxTranslate>
              </Typography>
            </DialogContent>

            <DialogActions>
              <Box className="actionContainer">
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleDeleteRow}
                >
                  <SbuxTranslate>{`Delete`}</SbuxTranslate>
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => setOpenDeleteDialogue(false)}
                >
                  <SbuxTranslate>{`Cancel`}</SbuxTranslate>
                </Button>
              </Box>
            </DialogActions>
          </SbuxDialog2>
        )}
      </Paper>
    </>
  );
};

export default DataManagementTabs;
