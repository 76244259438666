import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursEdit = ({ row, toggleDialogOpen, memoDataset }) => {
  const classes = useCss(styles);
  const initFormValues = {
    hoursCode: row.hoursCode,
    hoursName: row.hoursName,
    description: row.description,
  };
  const [formValue, setFormValue] = useState(initFormValues);
  const [hoursNameError, sethoursNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    memoDataset({ ...row });
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formValue, [name]: value };

    setFormValue(data);
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);

    memoDataset({ [name]: value });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formControl}
    >
      <Box>
        <SbuxTextField
          variant={"outlined"}
          label={"#"}
          disabled
          handleChange={handleChange}
          value={formValue.hoursCode}
          name={"hourCode"}
        />
      </Box>
      <Box>
        <SbuxTextField
          variant={"outlined"}
          label={"Hours Name"}
          isError={hoursNameError}
          placeholder={`Hours Name`}
          handleChange={handleChange}
          value={formValue.hoursName}
          name={"hoursName"}
          errorMessage={"Hours name required at least 1 character"}
          error={hoursNameError}
          required
        />
      </Box>
      <Box>
        <SbuxTextField
          variant={"outlined"}
          placeholder={`Description`}
          label={"Description"}
          handleChange={handleChange}
          value={formValue.description}
          name={"description"}
        />
      </Box>

      <Box className="fullWidth">
        <MainHoursConfigTable
          rowData={{
            ...row,
          }}
          toggleDialogOpen={toggleDialogOpen}
          memoDataset={memoDataset}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default MainHoursEdit;
