import { Paper, Box } from "@mui/material";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import {
  getStopContactData,
  deleteStopContact,
} from "../../../../../services/stopContact";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { EditStopContactsRow } from "./EditStopContactRow";
import { columns } from "./columns";
import SbuxLoader from "../../../../../components/SbuxLoader";
import {
  getStopContactDataSelector,
  getStopContactStatusSelector,
} from "../../../../../selectors/stopContactSelector";

const StopContactTable = () => {
  const dispatch = useDispatch();
  const [isDisableAddNewButton, setIsDisableAddNewButton] = useState(true);
  const [newStopContactData, setNewStopContactData] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const fetchStopContactData = async () => {
    await dispatch(getStopContactData());
  };

  const handleCheckboxChange = (event) => {
    setIsAllChecked(event.target.checked);

    const updatedTableData = newStopContactData.map((item) => ({
      ...item,
      isChecked: event.target.checked,
    }));

    setNewStopContactData(updatedTableData);
  };
  useEffect(() => {
    fetchStopContactData();
  }, []);

  const stopContactData = useSelector(getStopContactDataSelector);
  const stopContactStatus = useSelector(getStopContactStatusSelector);

  useEffect(() => {
    setNewStopContactData(stopContactData);
  }, [stopContactData]);

  const handleDelete = async () => {
    if (isAllChecked) {
      await dispatch(deleteStopContact([]));
    } else {
      await dispatch(deleteStopContact(selectedContacts));
    }
    setIsAllChecked(false);
    setIsDisableAddNewButton(true); // Disable button after deletion
  };

  const handleEditRowClick = (row) => {
    const updatedTableData = [...newStopContactData];
    const index = updatedTableData.findIndex(
      (item) => item.contactId === row.contactId
    );
    if (index > -1) {
      updatedTableData[index] = row;
      const selectedVal = updatedTableData.filter((item) => item.isChecked);
      selectedVal.length === 0
        ? setIsDisableAddNewButton(true)
        : setIsDisableAddNewButton(false);
      setSelectedContacts(selectedVal);
      setNewStopContactData(updatedTableData);
    }
  };

  if (stopContactStatus === "pending") {
    return <SbuxLoader />;
  }

  return (
    <SbuxEnhancedTable
      rows={newStopContactData}
      columns={columns}
      defaultSortingField={"initiationTimestamp"}
      defaultSortOrder={"asc"}
      RowSource={EditStopContactsRow}
      showFooter={true}
      showSearchBar={true}
      isManager={true}
      isAllChecked={isAllChecked}
      showAddNewButton
      handleAddNewButtonClick={handleDelete}
      isdisableAddNewButton={
        newStopContactData.length === 0 ||
        (selectedContacts.length === 0 && !isAllChecked)
      }
      addNewButtonTitle={<SbuxTranslate>{"Delete"}</SbuxTranslate>}
      isCustomButtonDisabled={!isAllChecked}
      handleEditRowClick={handleEditRowClick}
      handleRefreshClick={fetchStopContactData}
      handleCheckboxChange={handleCheckboxChange}
    />
  );
};

export default StopContactTable;
