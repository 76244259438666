import { useState, useEffect, forwardRef, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Popover,
  Slide,
  Stack,
} from "@mui/material";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DatePicker from "react-datepicker";
import { validateForm } from "../../utils/formValidation";
import SbuxTextField from "../SubxTextField";
import SbuxSelect from "../SbuxSelect";
import SbuxTimeDuration from "../SbuxTimeDuration";
import { LightTooltip } from "./styled";
import styles from "./styles";
import useCss from '../../hooks/useCss';

const operators = [
  [
    // { value: "eq", name: "Equal" },
    // { value: "ne", name: "Not Equal" },
    { key: "gt", value: "gt", name: "Greater than" },
    { key: "gte", value: "gte", name: "Greater than or equal" },
    { key: "lt", value: "lt", name: "Less than" },
    { key: "lte", value: "lte", name: "Less than or equal" },
  ],
  [{ key: "con", value: "con", name: "Contains" }],
];

const FilterForm = ({
  config,
  handleClose,
  onApplyFilters,
  open,
  withFormControls = {},
  anchorEl,
}) => {
  const classes = useCss(styles);
  const [filterList, setFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState();
  const [formControls, setFormControls] = useState(withFormControls);
  const [formValidity, setFormValidity] = useState({});

  useEffect(() => {
    const { items } = config;
    const filterList = [];
    const requiredFormControls = {};

    items.forEach((item) => {
      // Required fields are displayed on the form all the time
      if (!!item.required) {
        if (!formControls[item.name]) {
          const operators = operatorsFor(item.type);
          const defaultOp = operators[0].value;
          requiredFormControls[item.name] = {
            type: item.type,
            label: item.label,
            name: item.name,
            value: "",
            op: defaultOp,
            required: true,
          };
        }
      } else {
        // Not required fields
        filterList.push({
          key: item.name,
          name: item.label,
          value: item,
        });
      }
    });

    setFilterList(filterList);
    setFormControls({
      ...formControls,
      ...requiredFormControls,
    });
  }, []);

  const operatorsFor = (type) => {
    if (type === "text") {
      return operators[1];
    }

    return operators[0];
  };

  const addFormControlState = (item) => {
    const { name } = item;

    if (!!formControls[name]) {
      return;
    }

    const newFormControls = {
      ...formControls,
    };
    newFormControls[name] = { ...item };

    setFormControls(newFormControls);
  };

  /**
   * Remove filter from the form
   */
  const handleRemoveFilter = (name) => {
    if (formControls[name]) {
      const newFormControls = {
        ...formControls,
      };
      delete newFormControls[name];

      setFormControls(newFormControls);
    }
  };

  /**
   * Build form controls dynamically
   */
  const buildFormControls = () => {
    const elmControls = [];

    for (const key in formControls) {
      const {
        type,
        label,
        name,
        value = "",
        op,
        required = false,
      } = formControls[key];

      const operators = operatorsFor(type);
      const defaultOp = op ?? operators[0].value;
      // set default selection
      if (!op) {
        handleFormControlUpdate(name, { op: defaultOp });
      }

      const operatorList = (
        <SbuxSelect
          width={"auto"}
          menuItems={operators}
          defaultValue={defaultOp}
          handleSelectChange={(op) => handleFormControlUpdate(name, { op })}
          required
        />
      );

      const deleteAction = (
        <IconButton
          aria-label="data filters"
          className={!!required ? classes.invisible : undefined}
          onClick={() => {
            !required && handleRemoveFilter(name);
          }}
        >
          {!required ? <ClearIcon /> : <PriorityHighIcon />}
        </IconButton>
      );

      const validatorInfo = !!formValidity[name] ? (
        <LightTooltip title={formValidity[name]}>
          <IconButton
            className={classes.validator}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            aria-label="validator info"
          >
            <PriorityHighIcon />
          </IconButton>
        </LightTooltip>
      ) : undefined;

      const controlLabel = (
        <span>
          {label}{" "}
          {!!required ? (
            <span className={classes.validator}>*</span>
          ) : undefined}
        </span>
      );

      switch (type) {
        case "date": {
          elmControls.push(
            <Box className={classes.row}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <DatePicker
                name={name}
                selected={value}
                dateValue={value}
                onChange={(date) =>
                  handleFormControlUpdate(name, { value: date })
                }
                dateFormat="yyyy-MM-dd"
                popperProps={{ strategy: "fixed" }}
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }

        case "datetime": {
          elmControls.push(
            <Box className={classes.row}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <DatePicker
                name={name}
                className={classes.datePicker}
                selected={value}
                dateValue={value}
                showTimeSelect
                onChange={(date) =>
                  handleFormControlUpdate(name, { value: date })
                }
                timeFormat="HH:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                popperProps={{ strategy: "fixed" }}
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }

        case "timestamp": {
          break;
        }

        case "timeDuration": {
          elmControls.push(
            <Box className={classes.row}>
              {deleteAction}

              {controlLabel}

              {operatorList}

              <SbuxTimeDuration
                config={{
                  name: name,
                  hh: { min: 0 },
                  mm: { min: 0, max: 59 },
                  ss: { min: 0, max: 59 },
                }}
                withValues={value}
                onChange={(event, value) => {
                  handleFormControlUpdate(name, { value });
                }}
              />

              {validatorInfo}
            </Box>
          );
          break;
        }

        case "number":
        default: {
          elmControls.push(
            <Box className={classes.row}>
              {deleteAction}

              <span>
                {label}{" "}
                {!!required ? (
                  <span className={classes.validator}>*</span>
                ) : undefined}
              </span>

              {operatorList}

              <SbuxTextField
                name={name}
                value={value}
                handleChange={(event) =>
                  handleFormControlUpdate(name, { value: event.target.value })
                }
                required={required}
              />

              {validatorInfo}
            </Box>
          );

          break;
        }
      } // end-switch
    } // end-for

    return elmControls.length > 0 ? (
      elmControls
    ) : (
      <Box>No filters selected.</Box>
    );
  }; //buildForm

  /**
   * Update control's attribute with value
   */
  const handleFormControlUpdate = (ctrlName, updatedPart) => {
    const item = { ...formControls[ctrlName], ...updatedPart };
    const allFormControls = {
      ...formControls,
    };
    allFormControls[ctrlName] = item;

    setFormControls(allFormControls);
  };

  /**
   * Add selected filter to the form
   */
  const handleAddFilter = () => {
    if (!!selectedFilter && typeof selectedFilter === "object") {
      addFormControlState(selectedFilter);
    }
  };

  /**
   * Data transform filters spec, calls the callbackFn to notify the parent
   * component and closes the Popover
   */
  const applyFilters = (event) => {
    const filters = {};
    // remove emply filters
    for (const key in formControls) {
      const { op, value } = formControls[key];
      if (!!op && !!value) {
        filters[key] = { ...formControls[key] };
      }
    }

    onApplyFilters(filters);
    handleClose();
  };

  /**
   * Handle submit event of the form
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    const fields = Object.keys(formControls);
    const validation = validateForm(form.current, fields);

    if (!validation.invalid) {
      applyFilters(event);
    } else {
      setFormValidity(validation);
    }
  };

  const form = useRef(null);

  return (
    <Popover
      onClose={handleClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.actionTop}>
          <span>Filter List</span>

          <SbuxSelect
            menuItems={filterList}
            handleSelectChange={(selectedFilter) => {
              setSelectedFilter(selectedFilter);
            }}
          />

          <Button variant="contained" size="medium" onClick={handleAddFilter}>
            Add
          </Button>
        </Box>

        <Divider />

        <form
          method="post"
          noValidate
          autoComplete="off"
          ref={form}
          onSubmit={handleSubmit}
        >
          <Box className={classes.container}>{buildFormControls()}</Box>

          <Divider />

          <Box className={`${classes.actionButtons}`}>
            <Button variant="outlined" size="medium" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="medium"
            >
              Apply
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};

/**
 * SbuxDataFilters component
 */
const SbuxDataFilters = ({ config, onApplyFilters }) => {
  const classes = useCss(styles);
  const [formControls, setFormControls] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [operatorMap, setOperatorMap] = useState({});

  useEffect(() => {
    const flatMap = {};
    for (const ops of operators) {
      for (const op of ops) {
        flatMap[op.value] = op.name.toUpperCase();
      }
    }

    setOperatorMap(flatMap);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Value transformation to comply with the backend spec or the frontend
   * readability.
   */
  const transformFilterValueSpec = (type, value, readable = false) => {
    if (type === "timeDuration") {
      const { hh = 0, mm = 0, ss = 0 } = value;
      const calc = hh * 3600 + mm * 60 + ss;
      const trFn = (calc, hh, mm, ss) => {
        if (calc <= 0) {
          return `${calc} seconds.`;
        }
        const text = [];
        hh > 0 && text.push(`${hh} hour(s)`);
        mm > 0 && text.push(`${mm} minute(s)`);
        ss > 0 && text.push(`${ss} second(s)`);

        return text.join(", ");
      };
      // ms / s
      return !readable ? 1000 * Math.abs(calc) : trFn(calc, hh, mm, ss);
    }

    return value;
  };

  /**
   * Build filter payload part to comply with the backend spec
   */
  const buildFilterSpec = (formControls) => {
    const filterSpec = [];
    for (const key in formControls) {
      const { name, value, op, type } = formControls[key];
      filterSpec.push({
        field: name,
        op,
        value: transformFilterValueSpec(type, value),
      });
    }

    return filterSpec;
  };

  /**
   * Apply filter. Call backend
   */
  const handleApplyFilters = (formControls) => {
    setFormControls(formControls);

    // Notify change
    const filterSpec = buildFilterSpec(formControls);
    onApplyFilters(filterSpec);
  };

  /**
   * Handle the event that deletes a selected filter and sends request to the
   * backend
   */
  const handleDeleteFilter = (name) => {
    if (formControls[name]) {
      const newFormControls = {
        ...formControls,
      };
      delete newFormControls[name];

      setFormControls(newFormControls);

      // Notify change
      const filterSpec = buildFilterSpec(newFormControls);
      onApplyFilters(filterSpec);
    }
  };

  /**
   * Clears all filters and call the backend
   */
  const handleClearAllFilters = () => {
    setFormControls({});
    onApplyFilters([]);
  };

  /**
   * Build filter list to render
   */
  const buildFilterList = () => {
    const chipList = [];

    for (const key in formControls) {
      const {
        name,
        value,
        op,
        label,
        type,
        required = false,
      } = formControls[key];
      chipList.push(
        <LightTooltip
          title={`${label} ${operatorMap[op]} ${transformFilterValueSpec(
            type,
            value,
            true
          )}`}
        >
          <Chip
            label={label}
            onDelete={!required ? () => handleDeleteFilter(name) : null}
          />
        </LightTooltip>
      );
    }

    return chipList;
  };

  /**
   * Clear all filters Icon
   */
  const clearAllFilters =
    Object.keys(formControls).length > 0 ? (
      <LightTooltip title="Clear All">
        <IconButton
          // disableFocusRipple
          // disableRipple
          aria-label="clear all data filters"
          onClick={(event) => {
            handleClearAllFilters();
          }}
        >
          <ClearAllIcon color="action" />
        </IconButton>
      </LightTooltip>
    ) : undefined;

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <Box>
        <Stack direction="row" spacing={1} className={classes.filterBar}>
          <span className="title">Filters</span>
          <IconButton
            // disableFocusRipple
            // disableRipple
            aria-describedby={id}
            aria-label="data filters"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <Badge
              badgeContent={Object.keys(formControls).length}
              color="primary"
              classes={{ badge: classes.badge }}
            >
              <FilterListIcon color="action" />
            </Badge>
          </IconButton>

          {buildFilterList()}

          {clearAllFilters}
        </Stack>
      </Box>

      {open && (
        <FilterForm
          config={config}
          open={open}
          handleClose={handleClose}
          onApplyFilters={handleApplyFilters}
          withFormControls={formControls}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
};

export default SbuxDataFilters;
