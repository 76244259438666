import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { dialByPhoneNumber } from "../../../content/common/agentCall";
import { useDispatch } from "react-redux";
import { setHistoryDialNumberValidateStatus } from "../../../../services/history";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";

const PhoneNumberDialog = ({
  openDialPrompt,
  parsedPhoneNumber,
  toggleOpenDialPrompt,
}) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();

  const handleOkayDialNumber = () => {
    dispatch(setHistoryDialNumberValidateStatus(null));
    dialByPhoneNumber(
      parsedPhoneNumber.formattedPhoneNumber,
      toggleOpenDialPrompt,
      dispatch
    );
  };

  const dialogConfig = {
    title: <SbuxTranslate>{`Dial Phone Number`}</SbuxTranslate>,
    hasBackdrop: false,
    asModal: true,
    disableBackdropClose: false,
  };

  return (
    <SbuxDialog2
      open={openDialPrompt}
      config={dialogConfig}
      handleOnClose={toggleOpenDialPrompt}
    >
      <DialogContent dividers>
        <Typography variant="h5">
          <SbuxTranslate>{`Are you sure you would like to dial`}</SbuxTranslate>
          <span>{` ${parsedPhoneNumber.phoneNumber}?`}</span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button
            variant="contained"
            size="medium"
            onClick={handleOkayDialNumber}
          >
            <SbuxTranslate>{"Okay"}</SbuxTranslate>
          </Button>
          <Button
            variant="outlined"
            size="medium"
            onClick={toggleOpenDialPrompt}
          >
            <SbuxTranslate>{"Cancel"}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default PhoneNumberDialog;
