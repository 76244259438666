import React from "react";
import { Link, Box } from "@mui/material";
import Button from "@mui/material/Button";

import SbuxTranslate from "../../SbuxTranslate";
import defaultConfig from "../config/default.json";
import languageConfig from "../config/language.json";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateSettings,
  createOrUpdateLocaleSettings,
  getReOrderedTablist,
  getUpdatedTimezone,
} from "../../../services/settings";
import { USER_DEFAULT_TIMEZONE } from "../../../constants";
import styles from "../styles";
import useCss from "../../../hooks/useCss";
import {
  getUserConfigSelector,
  getLocaleSelector,
  getReOrderedTablistSelector,
  getSelectedTimezoneSelector,
} from "../../../selectors/settingsSelector";

const FooterList = ({ handleModal }) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();

  const config = useSelector(getUserConfigSelector);
  const locale = useSelector(getLocaleSelector);
  const reOrderedTablist = useSelector(getReOrderedTablistSelector);
  const selectedTimezone = useSelector(getSelectedTimezoneSelector);

  const handlSaveClick = async () => {
    let payload = null;
    if (config && config.hasOwnProperty("tabOrder")) {
      payload = {
        config: {
          ...config,
          tabOrder: reOrderedTablist,
          languageCode: locale,
          timezone: selectedTimezone
            ? selectedTimezone
            : defaultConfig.timezone,
        },
      };
    } else {
      payload = {
        config: {
          tabOrder: reOrderedTablist,
          languageCode: locale,
          timezone: selectedTimezone
            ? selectedTimezone
            : defaultConfig.config.timezone,
        },
      };
    }
    const timezone = selectedTimezone
      ? selectedTimezone
      : defaultConfig.config.timezone;
    handleModal();
    await dispatch(getUpdatedTimezone(timezone));
    await dispatch(createOrUpdateSettings(payload));
    await dispatch(createOrUpdateLocaleSettings(languageConfig.translations));
  };

  const handlCancelClick = async () => {
    handleModal();
    await dispatch(getReOrderedTablist("cancel"));
    await dispatch(getUpdatedTimezone(USER_DEFAULT_TIMEZONE));
  };

  const handleLinkClick = async () => {
    handleModal();
    const resetToDefaults = {
      languageCode: "en",
      default: languageConfig.translations,
    };
    await dispatch(getUpdatedTimezone(USER_DEFAULT_TIMEZONE));
    await dispatch(createOrUpdateSettings(defaultConfig));
    await dispatch(createOrUpdateLocaleSettings(resetToDefaults));
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        bottom: "3%",
      }}
    >
      <Box className={classes.actionButtons}>
        <Button variant="contained" size="medium" onClick={handlSaveClick}>
          <SbuxTranslate>{"Save"}</SbuxTranslate>
        </Button>

        <Button variant="outlined" size="medium" onClick={handlCancelClick}>
          <SbuxTranslate>{"Cancel"}</SbuxTranslate>
        </Button>
      </Box>
      <Box sx={{ fontSize: 18, flex: 1 }}>
        <Link component="button" onClick={handleLinkClick}>
          <SbuxTranslate>{"Click to reset to defaults"}</SbuxTranslate>
        </Link>
      </Box>
    </Box>
  );
};

export default React.memo(FooterList);
