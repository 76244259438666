const styles = (theme) => ({
  root: {
    width: 550,
    minHeight: 80,
    zIndex: 99999,
  },
  heading: {
    paddingTop: 0,
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginTop: "-32px",
  },
  time: {
    fontSize: 16,
    fontWeight: 600,
  },
});

export default styles;
