import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import SbuxTranslate from "../SbuxTranslate";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import { useDispatch } from "react-redux";
import { FILTER_DEFAULT_VALUE } from "../../constants";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { StyledFiltersButton } from "./styled";

const SbuxTableFilters = ({
  columns,
  title,
  defaultFilterColumns = [],
  setFilteredItem,
}) => {
  const classes = useCss(styles);

  let columnNameArray = [];
  const defaultFiler =
    defaultFilterColumns && defaultFilterColumns.length > 0
      ? defaultFilterColumns
      : [];

  const [open, setOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(true);
  const [seletedItem, setSeltectedItem] = useState(defaultFiler);
  const dispatch = useDispatch();
  const handleModal = () => {
    setOpen(!open);
  };

  const handleToggle = async (event, value) => {
    const currentIndex = seletedItem.indexOf(value);
    let newChecked = [...seletedItem];
    if (value === "all" && event.target.checked) {
      newChecked = [...columnNameArray];
    } else if (value === "all" && !event.target.checked) {
      newChecked = [];
    } else if (currentIndex === -1) {
      newChecked.push(value);
      newChecked.sort();
      const isMatchedData = columnNameArray
        .filter((f) => f !== "all")
        .sort()
        .every((element, index) => element === newChecked[index]);

      if (isMatchedData) newChecked.push("all");
      else newChecked.filter((f) => f !== "all");
    } else {
      Array.isArray(newChecked) && newChecked.splice(currentIndex, 1);
      const indexOfAllText = newChecked.indexOf("all");
      if (indexOfAllText >= 0) {
        newChecked.splice(indexOfAllText, 1);
      }
    }

    await setSeltectedItem(newChecked);
    await dispatch(setFilteredItem(newChecked));
    setIsFiltered(true);
  };

  const getFormLabel = (key, value) => {
    columnNameArray.push(value);
    return (
      <FormControlLabel
        key={key}
        sx={{ padding: "10px" }}
        onChange={(e) => handleToggle(e, value)}
        control={
          <Checkbox
            checked={seletedItem.indexOf(value) !== -1}
            disableRipple
            tabIndex={-1}
          />
        }
        label={
          <span style={{ fontSize: 14 }}>
            <SbuxTranslate>{key}</SbuxTranslate>
          </span>
        }
      />
    );
  };

  const list = () => (
    <Box role="presentation" onKeyDown={handleModal}>
      <List>
        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText sx={{ textAlign: "center" }}>
            <span style={{ fontSize: 18 }}>{title}</span>
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
      <List>
        {columns.map((column, index) => (
          <ListItem
            key={column.id}
            sx={{ width: 200, paddingBottom: 0, paddingTop: 0 }}
          >
            {column.hasOwnProperty("subTitle") ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{ fontSize: 16, fontWeight: 600, textAlign: "left" }}
                >
                  {column.subTitle}
                </span>
                <Divider />
                {column.filterList.map((subColumn, index) =>
                  getFormLabel(subColumn.key, subColumn.value)
                )}
              </Box>
            ) : (
              getFormLabel(column.key, column.value)
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      <StyledFiltersButton
        isFiltered={
          isFiltered && seletedItem.length > 0 && seletedItem.includes("all")
        }
        onClick={handleModal}
      />
      <div id="container-table">
        <Box sx={{ overflow: "hidden", flex: 1 }}>
          <Drawer
            anchor={"right"}
            open={open}
            PaperProps={{
              sx: {
                position: "absolute",
              },
            }}
            ModalProps={{
              container: document.getElementById("container-table"),
              disableEnforceFocus: true,
              onClose: handleModal,
              sx: {
                position: "absolute",
                overflow: "hidden",
                height: "67.5vh",
                marginTop: 7.5,
                marginRight: 2,
                marginBottom: 2,
              },
            }}
            variant="temporary"
          >
            {list()}
          </Drawer>
        </Box>
      </div>
    </Box>
  );
};

export default SbuxTableFilters;
