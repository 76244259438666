import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";

import styles from "../../styles";
import useCss from "../../../../../hooks/useCss";
import { StyledDeleteButton } from "../../styled";
import { getConnectStatusSelector } from "../../../../../selectors/connectSelector";

const RowSource = ({ row, handleDeleteRowClick }) => {
  const status = useSelector(getConnectStatusSelector);
  const [openConfirmDialog, setOpenConfigDialog] = useState(false);
  const classes = useCss(styles);

  const toggleDialogOpen = () => {
    setOpenConfigDialog(!openConfirmDialog);
  };

  const handleOnClickDeleteEvent = () => {
    if (!status.endsWith("pending")) {
      setOpenConfigDialog(true);
    }
  };

  const dialogConfig = {
    title: "Confirm",
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["Name"]}
        </SbuxStyledTableCell>

        <SbuxStyledTableCell>
          <StyledDeleteButton
            onClick={handleOnClickDeleteEvent}
            disableFocusRipple
            disableRipple
          />
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>

      {openConfirmDialog && (
        <SbuxDialog2
          open={openConfirmDialog}
          config={dialogConfig}
          handleOnClose={toggleDialogOpen}
        >
          <DialogContent dividers>
            <Typography variant="h5">
              <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
              <span>{`${row["Name"]}?`}</span>
            </Typography>
          </DialogContent>

          <DialogActions>
            <Box className="actionContainer">
              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  handleDeleteRowClick(row);
                  setOpenConfigDialog(false);
                }}
              >
                Okay
              </Button>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  setOpenConfigDialog(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </SbuxDialog2>
      )}
    </>
  );
};

export default RowSource;
