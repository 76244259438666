import { useState, useEffect } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Divider,
  Box,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";
import SbuxCustomTable from "../../../../components/SbuxTable/SbuxCustomTable";
import { dataMgtColumns } from "../../manager/table/dataMgt/columns";
import SbuxCustomRow from "../../../../components/SbuxTable/SbuxCustomRow";
import { DataManagementCustomInput } from "./DataManagementCustomInput";

const DataManagementDialog = ({
  title,
  open,
  toggleDialogOpen,
  tableName,
  onSave,
  row,
  columns,
  addNew,
  rows,
  crudOperations,
}) => {
  const classes = useCss(styles);
  const [rowData, setRowData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const tempFormValues = new Map();
  const [formValues, setFormValues] = useState(new Map());

  useEffect(() => {
    if (row && !addNew) {
      const initialData = Object.entries(row)
        .map(([label, value]) => {
          const item = columns.find((column) => column.id === label);

          if (!!item) {
            tempFormValues.set(item?.label, { ...item, value: value || "" });

            return {
              label: item?.label,
              value: value || "",
              disabled: item?.disabled,
              type: item?.type,
              uiPosition: item?.uiPosition,
              required: item?.required,
              maxLength: item?.maxLength,
              lockedForEdit: item?.lockedForEdit,
              partitionKey: item?.partitionKey,
              name: item?.id,
            };
          }

          return undefined; // hideOnUi = true
        })
        .filter((data) => !!data);

      setFormValues(tempFormValues);
      setRowData(initialData.sort((x, y) => x.uiPosition - y.uiPosition));
    } else {
      const emptyData = columns.map((column) => {
        const item = {
          label: typeof column === "string" ? column : column.label,
          value: "",
          type: column.type,
          uiPosition: column?.uiPosition,
          required: column?.required,
          maxLength: column?.maxLength,
          lockedForEdit: false,
          partitionKey: column?.partitionKey,
          name: column?.id,
        };
        tempFormValues.set(item.label, item);
        return item;
      });
      setFormValues(tempFormValues);
      setRowData(emptyData);
    }
  }, [row, columns]);

  const handleOkayClick = async () => {
    const listDataPayload = {
      payload: {
        attributes: rowData.map((data) => {
          const item = columns.find((column) => column.label === data.label);
          const modifiedValue = formValues.get(item.label);
          return {
            name: item.id,
            value: modifiedValue?.value,
          };
        }),
      },
      tableName: tableName,
      isEdit: !addNew,
    };
    onSave(listDataPayload);
  };

  const handleInputChange = (data, value, formValues) => {
    setFormValues(formValues);

    let isError = false;
    for (const [key, eachControl] of formValues?.entries()) {
      if (eachControl?.isError) {
        isError = true;
      } else if (eachControl.required && eachControl.value?.length === 0) {
        isError = true;
      }
    }
    setIsSaveDisabled(isError);
  };

  const dialogConfig = {
    title,
  };
  const isFormDisable = !crudOperations?.includes("UPDATE") && !addNew;
  return (
    <>
      <SbuxDialog2
        open={open}
        config={dialogConfig}
        handleOnClose={toggleDialogOpen}
      >
        <DialogContent dividers>
          <Box>
            <Box
              component="form"
              className={`${classes.formControl}`}
              noValidate
              autoComplete="off"
            >
              {rowData.map((data, idx) => {
                const isDisabled = !!data?.lockedForEdit || isFormDisable;
                return (
                  <DataManagementCustomInput
                    key={idx}
                    rows={rows}
                    formValues={formValues}
                    data={data}
                    idx={idx}
                    label={data?.label}
                    value={rowData[idx]?.value}
                    handleInputChange={handleInputChange}
                    disabled={isDisabled}
                    type={data?.type}
                    required={data?.required}
                    maxLength={data?.maxLength}
                    isError={data?.isError}
                    errorMessage={data?.errorMessage}
                  />
                );
              })}
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box className="actionContainer">
            <Button
              onClick={handleOkayClick}
              variant="contained"
              size="medium"
              disabled={isSaveDisabled}
            >
              <SbuxTranslate>{"Save"}</SbuxTranslate>
            </Button>
            <Button variant="outlined" size="medium" onClick={toggleDialogOpen}>
              <SbuxTranslate>{"Cancel"}</SbuxTranslate>
            </Button>
          </Box>
        </DialogActions>
      </SbuxDialog2>
    </>
  );
};

export default DataManagementDialog;
