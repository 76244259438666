import { useState } from "react";
import SbuxTextField from "../../../../../components/SubxTextField";
import SbuxCustomRow from "../../../../../components/SbuxTable/SbuxCustomRow";
import SbuxCustomTable from "../../../../../components/SbuxTable/SbuxCustomTable";
import useTextTranslate from "../../../hooks/useTextTranslate";
import { temporaryColumns } from "../temporaryMsg/columns";
import { Box } from "@mui/material";
import { getTextLengthErrorMessage } from "../../utils";
import styles from "../../styles";
import useCss from "../../../../../hooks/useCss";

const TemporaryMsgAdd = ({ row, sequenceNumber, handleTemporaryMsgRow }) => {
  const classes = useCss(styles);
  // const descriptionPlaceHolder = useTextTranslate("Description");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [code, setCode] = useState(sequenceNumber);
  const [info, setInfo] = useState("");
  const [rowObj, setRowObj] = useState(row);
  const [fileNameLengthError, setFileNameLengthError] = useState(false);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    setDescriptionError(value.length < 1 ? true : false);
    const addRow = {
      ...rowObj,
      description: value,
    };
    setRowObj(addRow);
    handleTemporaryMsgRow(addRow);
  };

  const handleInfoChange = (event) => {
    const value = event.target.value;
    setInfo(value);
    const addRow = {
      ...rowObj,
      info: value,
    };
    if (value.length === 150) {
      setFileNameLengthError(true);
    } else {
      setFileNameLengthError(false);
    }
    setRowObj(addRow);
    handleTemporaryMsgRow(addRow);
  };

  return (
    <Box>
      <Box
        component="form"
        className={classes.formControl}
        noValidate
        autoComplete="off"
      >
        <Box>
          <SbuxTextField label="Code" disabled value={code} />
        </Box>

        <Box>
          <SbuxTextField
            label="Description"
            // placeholder={descriptionPlaceHolder}
            value={description}
            handleChange={handleDescriptionChange}
            errorMessage={"Description required at least 1 character"}
            isError={descriptionError}
            error={descriptionError}
            required
          />
        </Box>

        <Box>
          <SbuxTextField
            label="Info"
            value={info}
            handleChange={handleInfoChange}
            errorMessage={
              fileNameLengthError ? getTextLengthErrorMessage(150) : null
            }
            isError={fileNameLengthError}
            error={fileNameLengthError}
            displayCounter={true}
            maxLength={150}
            multiline={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TemporaryMsgAdd;
