import React from "react";
import { List, Box } from "@mui/material";
import SbuxTranslate from "../../SbuxTranslate";
import { Divider } from "@mui/material";
import DragItem from "./dragdrop";
import {
  SETTINGS_TAB_ORDER_TITLE,
  SETTINGS_TAB_ORDER_SUB_TITLE,
} from "../../../constants";
import styles from "../styles";
import useCss from "../../../hooks/useCss";

const TabList = () => {
  const classes = useCss(styles);
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
      }}
    >
      <Box className={classes.dragTitle}>
        <SbuxTranslate>{"Tab Order"}</SbuxTranslate>
      </Box>
      <Box className={classes.dragSubTitle}>
        <SbuxTranslate>
          {"(Re-arrange the tab name by dragging and dropping)"}
        </SbuxTranslate>
      </Box>
      <DragItem />
      <Divider sx={{ marginTop: 1 }} />
    </Box>
  );
};

export default React.memo(TabList);
