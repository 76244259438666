import { createSlice } from "@reduxjs/toolkit";
import {
  getCcpCredentials,
  getCcpInitializedStatus,
  getCcpAgent,
  getCcpContact,
  getCcpOsvCdata,
  getCcpContactStatus,
  getCcpValidationButtonStatus,
} from "../services/ccp";

const initialState = {
  status: "idle",
  ccpCredentials: {},
  isCcpInitialized: false,
  ccpAgent: {
    agentName: null,
    agentEmailId: null,
    agentStatus: null,
    agentRoutingName: null,
    agentRoutingLob: null,
    agentRoutingQueues: [],
    endpoints: [],
    queues: [],
  },
  ccpContact: {
    contactId: null,
    contactAttributes: null,
    contactData: null,
  },
  ccpContactStatus: null,
  ccpContactMethod: null,
  ccpValidationButtonStatus: false,
};

const ccpSlice = createSlice({
  name: "ccp",
  initialState,
  reducers: {
    clearCCPContact: (state, { payload }) => {
      state.ccpContact = {
        contactId: null,
        contactAttributes: null,
        contactData: null,
      };
    },
    clearCcpCredentials: (state) => {
      state.ccpCredentials = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCcpCredentials.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getCcpCredentials.fulfilled, (state, action) => {
        const { payload: { data: { connectFederationTokens = {} } } = {} } =
          action;
        state.status = "success";
        state.ccpCredentials = connectFederationTokens;
      })
      .addCase(getCcpCredentials.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(getCcpInitializedStatus, (state, action) => {
        const { payload } = action;
        state.isCcpInitialized = payload;
      })
      .addCase(getCcpAgent, (state, action) => {
        const {
          agentStatus,
          configuration: { name, username, routingProfile },
          endpoints,
        } = action.payload;
        state.ccpAgent.agentName = name;
        state.ccpAgent.agentEmailId = username;
        state.ccpAgent.agentStatus = agentStatus;
        state.ccpAgent.agentRoutingName = routingProfile.name;
        state.ccpAgent.agentRoutingQueues = routingProfile.queues;
        state.ccpAgent.agentRoutingLob =
          routingProfile.name && routingProfile.name.split("-")[0];
        state.ccpAgent.endpoints = endpoints;
        state.ccpAgent.queues = routingProfile.queues.reduce((acc, cur) => {
          cur.name && acc.push(cur.name);
          return acc;
        }, []);
      })
      .addCase(getCcpContact, (state, action) => {
        const {
          payload: { contactId, contactAttributes, contactData },
        } = action;
        if (!!contactId) {
          state.ccpContact.contactId = contactId;
        }
        if (!!contactAttributes) {
          state.ccpContact.contactAttributes = contactAttributes;
        }
        if (!!contactData) {
          state.ccpContact.contactData = contactData;
        }
      })
      .addCase(getCcpContactStatus, (state, action) => {
        const { payload } = action;
        if (!!payload) {
          const { status, method } = payload;
          state.ccpContactStatus = status;
          // Only update value when it's available
          if (!!method) {
            state.ccpContactMethod = method;
          }
        } else {
          state.ccpContactStatus = payload;
          state.ccpContactMethod = payload;
        }
      })
      .addCase(getCcpValidationButtonStatus, (state, action) => {
        const { payload } = action;
        state.ccpValidationButtonStatus = payload;
      });
  },
});
export const { clearCCPContact, clearCcpCredentials } = ccpSlice.actions;
export default ccpSlice.reducer;
