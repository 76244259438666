import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Paper } from "@mui/material";
import DragList from "./DragList";
import { getReOrderedTablist } from "../../../../services/settings";
import { getReOrderedTablistSelector } from "../../../../selectors/settingsSelector";

const DragItem = () => {
  const dispatch = useDispatch();

  const reOrderedTablist = useSelector(getReOrderedTablistSelector);

  const [tabOrders, setTabOrders] = useState([]);

  useEffect(() => {
    reOrderedTablist && setTabOrders(reOrderedTablist);
  }, [reOrderedTablist]);

  const reOrder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reOrder(
      tabOrders,
      result.source.index,
      result.destination.index
    );
    setTabOrders(items);
    await dispatch(getReOrderedTablist(items));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <Paper
            elevation={1}
            sx={{
              padding: 2,
              marginTop: 1,
              width: "83%",
              height: "230px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <DragList elements={reOrderedTablist} />
            {provided.placeholder}
          </Paper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragItem;
