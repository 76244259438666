import { useCallback, useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Typography,
  AppBar,
  Container,
  Toolbar,
  Drawer,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./styles";
import useCss from "../hooks/useCss";
import { StyledDrawerButton } from "./styled";

const NavDrawer = ({ cognitoUserId, children }) => {
  const classes = useCss(styles);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <StyledDrawerButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
      />

      {openDrawer && (
        <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}>
          <Box className={classes.drawerContainer}>
            <Box className={classes.drawerContent}>{children}</Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default NavDrawer;
