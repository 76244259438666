import { useState, useEffect } from "react";
import SbuxSelect from "../../../../../../components/SbuxSelect";
import SbuxCustomRow from "../../../../../../components/SbuxTable/SbuxCustomRow";
import ClosureMsgAutoCompleteTime from "./ClosureMsgAutoCompleteTime";
import { getTime } from "../../../../../../utils/timeUtil";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import styles from "../../../styles";
import { DATE_TIME_BASED_ON_TIMEZONE } from "../../../../../../constants";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import { DATE_VALIDATION_ERROR_MSG } from "../../../../../../constants";
import moment from "moment-timezone";
import useCss from "../../../../../../hooks/useCss";
import { useTheme } from "@mui/material/styles";

const pickerType = {
  1: {
    name: "Start Time",
  },
  2: {
    name: "End Time",
  },
};

const ClosureMsgTimePicker2 = ({
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  timeZoneValue,
  timeZoneList,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleTimeZoneChange,
  disabled = false,
  row = null,
  type,
}) => {
  const theme = useTheme();
  const [sDate, setSDate] = useState(startDate);
  const [eDate, setEDate] = useState(endDate);
  const classes = useCss(styles);

  useEffect(() => {
    setSDate(startDate);
    setEDate(endDate);
  }, [startDate, endDate]);

  const typeOfStartTime = type === 1;
  const typeOfEndTime = type === 2;
  const { name } = pickerType[type];

  return (
    <Box className={classes.timePicker}>
      <Box>
        <DatePicker
          required
          wrapperClassName={
            typeOfStartTime && sDate === null
              ? classes.boderColor
              : typeOfEndTime && eDate === null
              ? classes.boderColor
              : typeOfEndTime && eDate < sDate
              ? classes.boderColor
              : ""
          }
          className={classes.datePicker}
          selected={typeOfStartTime ? startDate : endDate}
          dateValue={typeOfStartTime ? startDate : endDate}
          minDate={moment().toDate()}
          onChange={(date) => handleDateChange({ date, name })}
          timeFormat="YYYY-MM-DD"
          disabled={row && row.status === "Expired"}
          popperPlacement="bottom-start"
        />
      </Box>

      {((typeOfStartTime && sDate === null) ||
        (typeOfEndTime && eDate === null) ||
        (typeOfEndTime && moment(eDate) < moment(sDate))) && (
        <Box>
          <Tooltip
            title={
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.white.main,
                  fontSize: 14,
                }}
              >
                {DATE_VALIDATION_ERROR_MSG}
              </Box>
            }
          >
            <IconButton
              disableFocusRipple
              disableRipple
              sx={{
                cursor: "pointer",
                color: theme.palette.redAlert.main,
                fontSize: 16,
                width: "1vw",
                marginTop: 0.5,
                height: 18,
                "&:focus": {
                  outline: 0,
                },
                "&.MuiIconButton-root": {
                  padding: 0,
                  paddingLeft: "5px",
                },
              }}
            >
              <ErrorOutlineOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {typeOfStartTime ? (
        <>
          <Box>
            <ClosureMsgAutoCompleteTime
              startTimeOptions={getTime(null)}
              name={name}
              startDate={startDate}
              endDate={endDate}
              startTimeValue={startTimeValue}
              endTimeValue={endTimeValue}
              handleStartTimeChange={(time) =>
                handleStartTimeChange({ time, name })
              }
              timeZone={timeZoneValue}
              disabled={row && row.status === "Expired"}
            />
          </Box>
          <Box className="last">
            <SbuxSelect
              menuItems={timeZoneList}
              defaultValue={timeZoneValue}
              value={timeZoneValue}
              handleSelectChange={(timeZone) => handleTimeZoneChange(timeZone)}
              disabled={row && row.status === "Expired"}
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <ClosureMsgAutoCompleteTime
              endTimeOptions={getTime(endTimeValue)}
              name={name}
              startDate={startDate}
              endDate={endDate}
              startTimeValue={startTimeValue}
              endTimeValue={endTimeValue}
              timeZone={timeZoneValue}
              handleEndTimeChange={(time) =>
                handleEndTimeChange({ time, name })
              }
              disabled={row && row.status === "Expired"}
            />
          </Box>
          <Box className="last bold">
            {`${DATE_TIME_BASED_ON_TIMEZONE} ${moment
              .tz(moment(), timeZoneValue)
              .format("MM/DD/YYYY hh:mm a")}`}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ClosureMsgTimePicker2;
