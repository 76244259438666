import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxSelect from "../../../../../components/SbuxSelect";
import SbuxTextField from "../../../../../components/SubxTextField";
import { useDispatch } from "react-redux";
import {
  getTextToSpeech,
  getResetTextToSpeech,
} from "../../../../../services/textToSpeech";
import styles from "../../styles";
import { getTextLengthErrorMessage } from "../../utils";
import useCss from "../../../../../hooks/useCss";
import {
  getVoiceSelector,
  getLanguageSelector,
} from "../../../../../selectors/userInstanceSelector";
import { getAudioContentSelector } from "../../../../../selectors/textToSpeechSelectors";

const MediaTextToSpeech = ({
  selectedLanguageCode,
  handleMediaInfo,
  handleDisableSaveBtn,
}) => {
  const classes = useCss(styles);

  const voice = useSelector(getVoiceSelector);
  const language = useSelector(getLanguageSelector);
  const audioContent = useSelector(getAudioContentSelector);

  const [selectedVoice, setSelectedVoice] = useState("");
  const [voices, setVoices] = useState([]);
  const [text, setText] = useState("");
  const [blobUrl, setBlobUrl] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const audioRef = useRef();
  const dispatch = useDispatch();
  const [textLengthError, setTextLengthError] = useState(false);
  const resetAudio = () => {
    dispatch(getResetTextToSpeech());
    setBlobUrl("");
    setText("");
    setTextLengthError(false);
    handleDisableSaveBtn(false);
    if (audioRef.current) {
      audioRef.current.load();
    }
  };
  const handleClear = (e) => {
    e.preventDefault();
    resetAudio();
    handleDisableSaveBtn(false);
    handleMediaInfo(null);
  };
  const handleCreateOrUpdateTextToSpeech = (e) => {
    e.preventDefault();

    const selectedLanguage = language.find(
      (l) => l.code === selectedLanguageCode
    );
    if (selectedLanguage && selectedLanguage.textToSpeechCode) {
      setDisableBtn(false);
      let payload = {
        text: text,
        languageCode: selectedLanguage.textToSpeechCode,
        voice: selectedVoice?.id,
        engine: selectedVoice?.engine,
        fileType: "audio/mp3",
      };

      // Dispatch the action with the result object
      dispatch(getTextToSpeech(payload));
      setDisableBtn(true);
    } else {
      setDisableBtn(true);
    }
    return false;
  };
  useEffect(() => {
    if (audioContent) {
      const content = "data:audio/wav;base64," + audioContent;
      setBlobUrl(content);
      const result = {
        fileContent: content,
        fileName: "record.wav",
        file: audioContent,
        isTextToSpeech: true,
      };
      handleMediaInfo(result);
    } else {
      setBlobUrl("");
    }
  }, [audioContent]);
  useEffect(() => {
    resetAudio();
  }, []);
  useEffect(() => {
    let voices = [];
    if (selectedLanguageCode) {
      const selectedLanguage = language.find(
        (l) => l.code === selectedLanguageCode
      );
      if (selectedLanguage && selectedLanguage.textToSpeechCode) {
        setDisableBtn(false);
        const v = language.find((r) => r.code === selectedLanguageCode);
        if (v && v.voice) {
          voices = v.voice.map((item) => {
            return {
              name: item?.displayName,
              value: item,
            };
          });
          setSelectedVoice(v.voice[0]);
        } else {
          resetAudio();
          handleMediaInfo(null);
        }
      } else {
        setDisableBtn(true);
        setSelectedVoice("");
      }
    }

    setVoices(voices);
  }, [selectedLanguageCode, voice]);
  const onVoiceChange = (value) => {
    setDisableBtn(false);
    setSelectedVoice(value);
  };
  const handleTextChange = (event) => {
    const value = event.target.value;
    if (value.length > 2000) {
      setTextLengthError(true);
      handleDisableSaveBtn(true);
    } else {
      setTextLengthError(false);
      handleDisableSaveBtn(false);
    }
    setDisableBtn(false);
    setText(value);
  };

  return (
    <Box className={classes.tabContent}>
      <Box className={classes.formControl}>
        {voices && voices.length ? (
          <>
            <SbuxSelect
              label={<SbuxTranslate>{`Choose a voice`}</SbuxTranslate>}
              disabled={!voices || !voices.length}
              menuItems={voices}
              defaultValue={selectedVoice}
              value={selectedVoice}
              handleSelectChange={(value) => onVoiceChange(value)}
            />
          </>
        ) : null}

        {!voices ||
          (!voices.length && (
            <Box className={classes.warningText}>
              <SbuxTranslate>{`No text to speech available for this language`}</SbuxTranslate>
            </Box>
          ))}
        <div className={!voices || !voices.length ? classes.disabledBox : null}>
          <Box>
            <SbuxTextField
              label={<SbuxTranslate>{`Text`}</SbuxTranslate>}
              variant={"outlined"}
              value={text}
              handleChange={handleTextChange}
              rows={4}
              multiline={true}
              maxLength={2000}
              errorMessage={
                textLengthError ? getTextLengthErrorMessage(2000) : null
              }
              isError={textLengthError}
              error={textLengthError}
              displayCounter={true}
            />
          </Box>
          <Box className={classes.textToSpeachActionContainer}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleCreateOrUpdateTextToSpeech}
              disabled={!text || disableBtn || textLengthError}
            >
              Convert
            </Button>
            <Button variant="outlined" size="medium" onClick={handleClear}>
              Clear
            </Button>
          </Box>
          <Box className={classes.t2sAudioPlayer}>
            <audio
              ref={(audio) => {
                audioRef.current = audio;
              }}
              src={blobUrl}
              controls
            />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default MediaTextToSpeech;
