import { useCallback, useEffect, useState } from "react";
import { getDataGridColumns } from "./dataGridColumns";
import { getDataGridRows } from "./dataGridRows";
import CurrentDataGrid from "./CurrentDataGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  MISSED_CALL_STATUS,
  REJECTED_CALL_STATUS,
  VAR_TRANSCRIPTS_ENABLED,
  TICKET_OR_CASE_NUMBER_NEW,
  CALL_METHOD_ENDED,
} from "../../../constants";
import { getCcpContact } from "../../../services/ccp";
import { addCallHistory } from "../../../services/history";
import {
  saveContactToHistoryAction,
  updateCurrentCtr,
} from "../../../services/current";
import { getDataGridAttributes } from "./dataGridAttributes";
import useTextTranslate from "../hooks/useTextTranslate";
import { screenRecordingActions } from "../../../slices/screenRecordingSlice";
import {
  getTicketOrCaseNumberSelector,
  getTicketOrCaseNumberDefaultSelector,
  getSaveContactHistorySelector,
  getSaveContactHistoryDataSelector,
} from "../../../selectors/currentSelector";
import { getTranslatedTextSelector } from "../../../selectors/settingsSelector";
import {
  getCcpContactStatusSelector,
  getCcpContactMethodSelector,
  getCcpContactIdSelector,
  getCcpContactAttributesSelector,
} from "../../../selectors/ccpSelector";
import { getTicketOrCaseFeatureFlagEnabledSelector } from "../../../selectors/featureFlagSelector";
import { getConnectedInstanceSelector } from "../../../selectors/userInstanceSelector";

const Current = () => {
  const ccpContactStatus = useSelector(getCcpContactStatusSelector);
  const ccpContactMethod = useSelector(getCcpContactMethodSelector);
  const contactId = useSelector(getCcpContactIdSelector);
  const contactAttributes = useSelector(getCcpContactAttributesSelector);

  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const ticketOrCaseFeatureFlagEnabled = useSelector(
    getTicketOrCaseFeatureFlagEnabledSelector
  );
  const translatedText = useSelector(getTranslatedTextSelector);

  const ticketOrCaseNumber = useSelector(getTicketOrCaseNumberSelector);
  const ticketOrCaseNumberDefault = useSelector(
    getTicketOrCaseNumberDefaultSelector
  );
  const history = useSelector(getSaveContactHistorySelector);
  const historyData = useSelector(getSaveContactHistoryDataSelector);

  const dispatch = useDispatch();

  const [dataGridRows, setDataGridRows] = useState([]);
  const instanceId = connectedInstance ? connectedInstance.instanceId : null;
  const timeStampTranslatedText = useTextTranslate("Time");

  useEffect(() => {
    if (!!history) {
      handleCurrentContactClear(historyData);
    }
  }, [history]);

  useEffect(() => {
    if (contactAttributes && Object.keys(contactAttributes).length > 0) {
      setDataGridRows(
        getDataGridRows(
          contactAttributes,
          timeStampTranslatedText,
          connectedInstance?.appConfig?.contactAttributeOrdering
        )
      );
    }
  }, [contactAttributes, translatedText]);

  const handleCurrentContactClear = async (historyData) => {
    if (historyData && Object.keys(historyData).length > 0) {
      const ticketOrCaseNumberValue =
        ticketOrCaseNumber ??
        ticketOrCaseNumberDefault ??
        TICKET_OR_CASE_NUMBER_NEW;
      const currentSaveAttributes = await getDataGridAttributes(
        dataGridRows,
        historyData,
        ticketOrCaseNumberValue
      );
      currentSaveAttributes.instanceId = connectedInstance.instanceId;
      // TODO - to be re-implemented due to history not saving when default ticket# is used
      //      if (ticketOrCaseNumberValue !== ticketOrCaseNumberDefault) {
      if (
        !!ticketOrCaseNumberValue &&
        Object.keys(currentSaveAttributes ?? {}).length !== 0
      ) {
        let areTranscriptsEnabledForCall = false;
        const areTranscriptsEnabledInCallFlow =
          (contactAttributes?.[VAR_TRANSCRIPTS_ENABLED]?.value ?? "true") ===
          "true";
        const callMethod = currentSaveAttributes?.["Method"];

        if (
          callMethod &&
          [
            MISSED_CALL_STATUS,
            REJECTED_CALL_STATUS,
            CALL_METHOD_ENDED,
          ].includes(callMethod)
        ) {
          areTranscriptsEnabledForCall = false;
        } else {
          areTranscriptsEnabledForCall = areTranscriptsEnabledInCallFlow;
        }

        await Promise.all[
          (dispatch(
            addCallHistory({
              ...currentSaveAttributes,
              areTranscriptsEnabledForCall,
            })
          ),
          dispatch(
            updateCurrentCtr({ instanceId, contactId, ticketOrCaseNumberValue })
          ),
          dispatch(screenRecordingActions.resetContactRecordingStateAction()))
        ];
      }

      await dispatch(
        getCcpContact({
          contactId: null,
          contactAttributes: null,
          contactData: null,
        })
      );
      await dispatch(
        saveContactToHistoryAction({ history: false, historyData: null })
      );
      setDataGridRows([]);
    }
  };

  return (
    <CurrentDataGrid
      rows={dataGridRows}
      columns={getDataGridColumns(
        ccpContactStatus,
        ccpContactMethod,
        ticketOrCaseFeatureFlagEnabled
      )}
    />
  );
};

export default Current;
