import { useState, useEffect } from "react";
import SbuxCard from "../../../components/SbuxCard";
import SbuxDrawer from "../../../components/SbuxDrawer";
import AdminIvrTable from "./table/ivr/AdminIvrTable";
import MainHoursMaterTable from "./table/mainHours/MainHoursMaterTable";
import SbuxLoadingIndicator from "../../../components/SbuxLoadingIndicator";
import SbuxFallback from "../../../components/SbuxFallback";
import SbuxSnackbars from "../../../components/SbuxSnackbars";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getMainHours } from "../../../services/mainHours";
import { getResetQueue } from "../../../services/queue";
import { Box, Link } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import {
  SNACK_BARS_SEVERITY_SUCCESS,
  SNACK_BARS_SEVERITY_ERROR,
  ADMIN_QUEUE_DELETED_SUCCESSFULLY,
  ADMIN_HOURS_SAVED_SUCCESSFULLY,
  ADMIN_HOURS_ERROR_MESSAGE,
} from "../../../constants";
import QueueTabs from "./table/queue/QueueTable";
import FeatureFlagsTable from "../manager/table/featureFlags/FeatureFlagsTable";
import { getIvrTableStatusSelector } from "../../../selectors/ivrTableSelector";
import {
  getMainHoursDataSelector,
  getMainHoursIsSaveSelector,
  getMainHoursIsErrorSelector,
} from "../../../selectors/mainHoursSelector";
import { selectFeatureFlags } from "../../../selectors/featureFlagSelector";
import {
  getQueueDeleteStatusSelector,
  getQueueDeleteErrorSelector,
} from "../../../selectors/queueSelector";

const Admin = () => {
  const dispatch = useDispatch();

  const status = useSelector(getIvrTableStatusSelector);
  const mainHoursData = useSelector(getMainHoursDataSelector);
  const isSave = useSelector(getMainHoursIsSaveSelector);
  const isError = useSelector(getMainHoursIsErrorSelector);
  const featureFlags = useSelector(selectFeatureFlags);
  const queueDeleteStatus = useSelector(getQueueDeleteStatusSelector);
  const queueDeleteError = useSelector(getQueueDeleteErrorSelector);

  const [showIvrIndicator, setShowIvrIndicator] = useState(false);
  const [showMainHoursIndicator, setShowMainHoursIndicator] = useState(false);

  const [showFeatureFlagIndicator, setShowFeatureFlagIndicator] =
    useState(false);

  const [showQueueIndicator, setShowQueueIndicator] = useState(false);

  const [featureFlagsData, setFeatureFlagsData] = useState([]);

  const [openMainHoursSnackBar, setOpenMainHoursSnackBar] = useState(false);
  const [openQueueSnackBar, setOpenQueueSnackBar] = useState(false);

  useEffect(() => {
    setOpenMainHoursSnackBar(false);
    setOpenQueueSnackBar(false);
    dispatch(getResetQueue());
  }, []);

  useEffect(() => {
    (queueDeleteStatus === "deleted" || queueDeleteStatus === "rejected") &&
      setOpenQueueSnackBar((prevState) => !prevState);
  }, [queueDeleteStatus]);

  useEffect(() => {
    (isSave || isError) && setOpenMainHoursSnackBar((prevState) => !prevState);
  }, [isSave, isError]);

  useEffect(() => {
    if (featureFlags && featureFlags.length) {
      setFeatureFlagsData(
        featureFlags.filter((ff) => !!ff?.doesFeatureFlagBelongToAdminControls)
      );
    }
  }, [featureFlags]);

  const handleError = (error) => {
    clientLog({
      component: `Admin`,
      message: `Something went wrong in Admin Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in Admin`,
      `Something went wrong in Admin Component => ${error}`,
      `error`
    );
  };

  const handleIvrTableClick = () => {
    setShowIvrIndicator((prevInd) => !prevInd);
    setShowFeatureFlagIndicator(false);
    setShowMainHoursIndicator(false);
    setShowQueueIndicator(false);
  };
  const handleMainHoursTableClick = () => {
    setShowIvrIndicator(false);
    setShowFeatureFlagIndicator(false);
    setShowQueueIndicator(false);
    setShowMainHoursIndicator((prevInd) => !prevInd);
  };

  const handleFeatureFlagTableClick = () => {
    setShowIvrIndicator(false);
    setShowMainHoursIndicator(false);
    setShowQueueIndicator(false);
    setShowFeatureFlagIndicator((prevInd) => !prevInd);
  };

  const handleQueueTableClick = async () => {
    setShowIvrIndicator(false);
    setShowMainHoursIndicator(false);
    setShowFeatureFlagIndicator(false);
    setShowQueueIndicator((prevInd) => !prevInd);
  };

  const handleRefrehClick = async () => {
    showMainHoursIndicator && (await dispatch(getMainHours()));
  };

  const panelData = [
    {
      id: 1,
      name: "Business Controls",
      panels: [
        {
          id: 1,
          url: (
            <Box sx={{ display: "flex" }}>
              <Link
                component="button"
                onClick={handleMainHoursTableClick}
                sx={{
                  outline: "none !important",
                  textDecoration: "none",
                }}
              >
                <span
                  style={{
                    color: showMainHoursIndicator ? "#000000" : "#808080",
                    paddingTop: 1,
                  }}
                >{`Main Hours`}</span>
              </Link>
              {showMainHoursIndicator && (
                <ChevronRightIcon sx={{ fontSize: 21, paddingTop: 0.3 }} />
              )}
            </Box>
          ),
        },
        {
          id: 2,
          url: (
            <Box sx={{ display: "flex" }}>
              <Link
                component="button"
                onClick={handleFeatureFlagTableClick}
                sx={{
                  outline: "none !important",
                  textDecoration: "none",
                }}
              >
                <span
                  style={{
                    color: showFeatureFlagIndicator ? "#000000" : "#808080",
                    paddingTop: 1,
                  }}
                >{`Feature Flags`}</span>
              </Link>
              {showFeatureFlagIndicator && (
                <ChevronRightIcon sx={{ fontSize: 21, paddingTop: 0.3 }} />
              )}
            </Box>
          ),
        },
      ],
    },
    {
      id: 2,
      name: "IVR Controls",
      panels: [
        {
          id: 1,
          url: (
            <Box sx={{ display: "flex" }}>
              <Link
                component="button"
                onClick={handleIvrTableClick}
                sx={{
                  outline: "none !important",
                  textDecoration: "none",
                }}
              >
                <span
                  style={{
                    color: showIvrIndicator ? "#000000" : "#808080",
                    paddingTop: 1,
                  }}
                >{`Create Table`}</span>
              </Link>
              {showIvrIndicator && (
                <ChevronRightIcon sx={{ fontSize: 21, paddingTop: 0.2 }} />
              )}
            </Box>
          ),
        },
        {
          id: 2,
          url: (
            <Box sx={{ display: "flex" }}>
              <Link
                component="button"
                onClick={handleQueueTableClick}
                sx={{
                  outline: "none !important",
                  textDecoration: "none",
                }}
              >
                <span
                  style={{
                    color: showQueueIndicator ? "#000000" : "#808080",
                    paddingTop: 1,
                  }}
                >{`Queue`}</span>
              </Link>
              {showQueueIndicator && (
                <ChevronRightIcon sx={{ fontSize: 21, paddingTop: 0.2 }} />
              )}
            </Box>
          ),
        },
      ],
    },
  ];

  const renderTable = () => {
    if (showIvrIndicator) {
      return <AdminIvrTable />;
    } else if (showMainHoursIndicator) {
      return (
        <MainHoursMaterTable
          mainHoursData={mainHoursData}
          handleRefrehClick={handleRefrehClick}
        />
      );
    } else if (showFeatureFlagIndicator) {
      return (
        <FeatureFlagsTable
          featureFlags={featureFlagsData}
          handleRefrehClick={handleRefrehClick}
        />
      );
    } else if (showQueueIndicator) {
      return <QueueTabs />;
    }

    return null;
  };

  const handleOpenHoursClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMainHoursSnackBar(false);
  };

  const handleQueueClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenQueueSnackBar(false);
  };

  const handleMainHoursMessage = () => {
    return (
      <SbuxSnackbars
        open={openMainHoursSnackBar}
        message={
          isError ? ADMIN_HOURS_ERROR_MESSAGE : ADMIN_HOURS_SAVED_SUCCESSFULLY
        }
        severity={
          isError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
        }
        handleClose={handleOpenHoursClose}
      />
    );
  };

  const handleQueueMessage = () => {
    return (
      <SbuxSnackbars
        open={openQueueSnackBar}
        message={
          queueDeleteStatus === "deleted"
            ? ADMIN_QUEUE_DELETED_SUCCESSFULLY
            : queueDeleteStatus === "rejected" && queueDeleteError
        }
        severity={
          queueDeleteStatus === "rejected"
            ? SNACK_BARS_SEVERITY_ERROR
            : SNACK_BARS_SEVERITY_SUCCESS
        }
        handleClose={handleQueueClose}
      />
    );
  };

  return (
    <>
      {(isSave || isError) && handleMainHoursMessage()}
      {(queueDeleteStatus === "deleted" || queueDeleteStatus === "rejected") &&
        handleQueueMessage()}

      <ErrorBoundary
        FallbackComponent={SbuxFallback}
        onError={handleError}
        onReset={handleRefrehClick}
      >
        <SbuxCard className="insideTabContainer">
          {status === "rejected" ? (
            <SbuxFallback />
          ) : (
            <>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ flex: 1, position: "relative" }}>
                  <SbuxDrawer panelData={panelData} toggleSnackbar={true}>
                    {renderTable()}
                  </SbuxDrawer>
                </Box>
              </Box>
            </>
          )}
        </SbuxCard>
      </ErrorBoundary>
    </>
  );
};

export default Admin;
