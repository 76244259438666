import { useState, useEffect } from "react";
import SbuxCard from "../../../components/SbuxCard";
import SbuxDrawer from "../../../components/SbuxDrawer";
import { useSelector, useDispatch } from "react-redux";
import FeatureFlagsTable from "./table/featureFlags/FeatureFlagsTable";
import MediaMgtTabs from "./table/mediaMgt/MediaMgtTabs";
import ClosureMsgTabs from "./table/closureMsg/ClosureMsgTabs";
import DataManagementTabs from "./table/dataMgt/DataManagementTabs";
import TemporaryMsgTabs from "./table/temporaryMsg/TemporaryMsgTabs";
import StopContactTable from "./table/stopContact/StopContactTable";
import GenesysRecordingsTabs from "./table/genesysRecordings/GenesysRecordingsTabs";
import ConnectTable from "./table/connectMgt/ConnectTable";
import { getFeatureFlags } from "../../../services/featureFlags";
import {
  getClosureMsgFilterItem,
  getTemporaryMsgFilterItem,
  getMediaMgtFilterItem,
} from "../../../services/filter";
import {
  getMediaMgt,
  getResetMediaMgt,
  getMediaExpandCollapseInfo,
} from "../../../services/mediaMgt";

import {
  getClosureMsg,
  getResetClosureMsg,
} from "../../../services/closureMsg";

import {
  getTemporaryMsg,
  getResetTemporaryMsg,
} from "../../../services/temporaryMsg";

import {
  getGenesysRecordings,
  getResetGenesysRecordings,
} from "../../../services/genesysRecordings";

import { resetApiData } from "../../../services/connect";

import SbuxFallback from "../../../components/SbuxFallback";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import { panelData } from "./common/panelData";
import SbuxSnackbars from "../../../components/SbuxSnackbars";
import {
  PLEASE_WAIT,
  SNACK_BARS_SEVERITY_SUCCESS,
  SNACK_BARS_SEVERITY_ERROR,
  OOPS_ERROR_MESSAGE,
  MEDIAMGT_SAVED_SUCCESSFULLY,
  MEDIAMGT_DELETED_SUCCESSFULLY,
  CLOSUREMSG_SAVED_SUCCESSFULLY,
  MEDIAMGT_ERROR_MESSAGE,
  CLOSUREMSG_DELETED_SUCCESSFULLY,
  TEMPORARYMSG_SAVED_SUCCESSFULLY,
  CLOSUREMSG_DEFAULT_FILTER_ITEMS,
  TEMPEMSG_DEFAULT_FILTER_ITEMS,
  MEDIAMSG_DEFAULT_FILTER_ITEMS,
  LOADING_DATA,
  ROUTING_PROFILE_DELETION_SUCCESSFUL,
  DATA_MANAGEMENT_SAVED_SUCCESSFULLY,
  DATA_MANAGEMENT_DELETED_SUCCESSFULLY,
  CALLBACK_DELETED_SUCCESSFULLY,
} from "../../../constants";
import { Box } from "@mui/material";
import * as globalConfigSelectors from "../../../selectors/globalConfigSelectors";
import * as settingsSelectors from "../../../selectors/settingsSelector";
import * as userInstanceSelectors from "../../../selectors/userInstanceSelector";
import * as featureFlagSelectors from "../../../selectors/featureFlagSelector";
import * as mediaMgtSelectors from "../../../selectors/mediaMgtSelector";
import * as closureMsgSelectors from "../../../selectors/closureMsgSelector";
import * as temporaryMsgSelectors from "../../../selectors/temporaryMsgSelector";
import * as dataMgtSelectors from "../../../selectors/dataMgtSelector";
import * as filterSelectors from "../../../selectors/filterSelector";
import * as stopContactSelectors from "../../../selectors/stopContactSelector";
import * as connectSelectors from "../../../selectors/connectSelector";
import * as genesysRecordingsSelectors from "../../../selectors/genesysRecordingsSelector";

const Manager = () => {
  const dispatch = useDispatch();

  const globalConfigUi = useSelector(
    globalConfigSelectors.getGlobalConfigUiSelector
  );
  const admin = useSelector(settingsSelectors.getAdminSelector);
  const managerNav = useSelector(settingsSelectors.getManagerNavSelector);

  // User Instance Selectors
  const connectedInstance = useSelector(
    userInstanceSelectors.getConnectedInstanceSelector
  );
  const languages = useSelector(userInstanceSelectors.getLanguagesSelector);
  const business = useSelector(userInstanceSelectors.getBusinessSelector);
  const closureTabs = useSelector(userInstanceSelectors.getClosureTabsSelector);
  const genesysConfig = useSelector(userInstanceSelectors.getGenesysConfig);

  // Feature Flags Selectors
  const featureFlags = useSelector(featureFlagSelectors.selectFeatureFlags);
  const status = useSelector(
    featureFlagSelectors.getFeatureFlagsStatusSelector
  );

  // Media Management Selectors
  const mediaMgtData = useSelector(mediaMgtSelectors.getMediaMgtDataSelector);
  const isMMPending = useSelector(mediaMgtSelectors.getMMPendingSelector);
  const isMMSave = useSelector(mediaMgtSelectors.getMMSaveSelector);
  const isMMDelete = useSelector(mediaMgtSelectors.getMMDeleteSelector);
  const isMMError = useSelector(mediaMgtSelectors.getMMErrorSelector);
  const mediaExpandCollapseInfo = useSelector(
    mediaMgtSelectors.getMediaExpandCollapseInfoSelector
  );

  // Closure Messages Selectors
  const closureMsgData = useSelector(
    closureMsgSelectors.getClosureMsgDataSelector
  );
  const isCMPending = useSelector(
    closureMsgSelectors.getClosureMsgPendingSelector
  );
  const isCMSave = useSelector(closureMsgSelectors.getClosureMsgSaveSelector);
  const isCMDelete = useSelector(
    closureMsgSelectors.getClosureMsgDeleteSelector
  );
  const isCMError = useSelector(closureMsgSelectors.getClosureMsgErrorSelector);

  // Temporary Messages Selectors
  const temporaryMsgData = useSelector(
    temporaryMsgSelectors.getTemporaryMsgDataSelector
  );
  const isTMPending = useSelector(
    temporaryMsgSelectors.getTemporaryMsgPendingSelector
  );
  const isTMError = useSelector(
    temporaryMsgSelectors.getTemporaryMsgErrorSelector
  );
  const isTMSave = useSelector(
    temporaryMsgSelectors.getTemporaryMsgSaveSelector
  );

  // Data Management Selectors
  const isDMPending = useSelector(dataMgtSelectors.getDataMgtPendingSelector);
  const isDMSave = useSelector(dataMgtSelectors.getDataMgtSaveSelector);
  const isDMDelete = useSelector(dataMgtSelectors.getDataMgtDeleteSelector);
  const isDMError = useSelector(dataMgtSelectors.getDataMgtErrorSelector);

  // Filter Selectors
  const closureMsgFilterItem = useSelector(
    filterSelectors.getClosureMsgFilterItemSelector
  );
  const temporaryMsgFilterItem = useSelector(
    filterSelectors.getTemporaryMsgFilterItemSelector
  );
  const mediaMgtFilterItem = useSelector(
    filterSelectors.getMediaMgtFilterItemSelector
  );
  const closureMsgReset = useSelector(
    filterSelectors.getClosureMsgResetSelector
  );
  const temporaryMsgReset = useSelector(
    filterSelectors.getTemporaryMsgResetSelector
  );
  const mediaMgtReset = useSelector(filterSelectors.getMediaMgtResetSelector);

  // Stop Contact Selectors
  const stopContactData = useSelector(
    stopContactSelectors.getStopContactDataSelector
  );
  const isSCPending = useSelector(
    stopContactSelectors.getStopContactPendingSelector
  );
  const isSCError = useSelector(
    stopContactSelectors.getStopContactErrorSelector
  );
  const isSCDelete = useSelector(
    stopContactSelectors.getStopContactDeleteSelector
  );

  // Connect Selectors
  const connectStatus = useSelector(connectSelectors.getConnectStatusSelector);
  const connectErrorMessage = useSelector(
    connectSelectors.getConnectErrorMessageSelector
  );

  // Genesys Recordings Selectors
  const isQMPending = useSelector(
    genesysRecordingsSelectors.getQMPendingSelector
  );
  const isQMError = useSelector(genesysRecordingsSelectors.getQMErrorSelector);
  const genesysRecordingsData = useSelector(
    genesysRecordingsSelectors.getGenesysRecordingsDataSelector
  );

  const [openMediaMgt, setOpenMediaMgt] = useState(false);
  const [openClosureMsg, setOpenClosureMsg] = useState(false);
  const [openTemporaryMsg, setOpenTemporaryMsg] = useState(false);
  const [openGenesysRecordings, setOpenGenesysRecordings] = useState(false);
  const [openStopContact, setOpenStopContact] = useState(false);

  const [showOtherIndicator, setShowOtherIndicator] = useState(false);

  const [openMMSnackBar, setMMOpenSnackBar] = useState(false);
  const [openCMSnackBar, setCMOpenSnackBar] = useState(false);
  const [openTMSnackBar, setTMOpenSnackBar] = useState(false);
  const [openQMSnackBar, setQMOpenSnackBar] = useState(false);
  const [openDMSnackBar, setDMOpenSnackBar] = useState(false);
  const [openSCSnackBar, setSCOpenSnackBar] = useState(false);

  const [openConnectSnackBar, setOpenConnectSnackBar] = useState(false);

  const [featureFlagsData, setFeatureFlagsData] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState({});
  const [selectedName, setSelectedName] = useState(null);

  useEffect(() => {
    setMMOpenSnackBar(false);
    setCMOpenSnackBar(false);
    setTMOpenSnackBar(false);
    setDMOpenSnackBar(false);
    setQMOpenSnackBar(false);
    setSCOpenSnackBar(false);
    setOpenConnectSnackBar(false);
    (async () => {
      await dispatch(getResetMediaMgt());
      await dispatch(getResetClosureMsg());
      await dispatch(getResetTemporaryMsg());
      await dispatch(getResetGenesysRecordings());
      await dispatch(resetApiData());
    })();
  }, []);

  useEffect(() => {
    if (featureFlags && featureFlags.length) {
      setFeatureFlagsData(
        featureFlags.filter((ff) => !ff.doesFeatureFlagBelongToAdminControls)
      );
    }
  }, [featureFlags]);

  useEffect(() => {
    if (isMMPending || isMMSave || isMMDelete || isMMError) {
      setCMOpenSnackBar(false);
      setTMOpenSnackBar(false);
      setDMOpenSnackBar(false);
      setQMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setMMOpenSnackBar(true);
      setSCOpenSnackBar(false);
      (async () => {
        await dispatch(
          getMediaExpandCollapseInfo({
            mediaId: mediaExpandCollapseInfo?.mediaId,
            isExpanded: false,
          })
        );
      })();
    }
  }, [isMMPending, isMMSave, isMMDelete, isMMError]);

  useEffect(() => {
    if (isCMPending || isCMSave || isCMDelete || isCMError) {
      setMMOpenSnackBar(false);
      setTMOpenSnackBar(false);
      setDMOpenSnackBar(false);
      setQMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setCMOpenSnackBar(true);
      setSCOpenSnackBar(false);
    }
  }, [isCMPending, isCMSave, isCMDelete, isCMError]);

  useEffect(() => {
    if (isTMPending || isTMSave || isTMError) {
      setMMOpenSnackBar(false);
      setCMOpenSnackBar(false);
      setQMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setTMOpenSnackBar(true);
      setDMOpenSnackBar(false);
      setSCOpenSnackBar(false);
    }
  }, [isTMPending, isTMSave, isTMError]);

  useEffect(() => {
    if (isDMPending || isDMSave || isDMDelete || isDMError) {
      setMMOpenSnackBar(false);
      setCMOpenSnackBar(false);
      setQMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setTMOpenSnackBar(false);
      setDMOpenSnackBar(true);
      setSCOpenSnackBar(false);
    }
  }, [isDMPending, isDMSave, isDMDelete, isDMError]);

  useEffect(() => {
    if (isQMPending || isQMError) {
      setMMOpenSnackBar(false);
      setCMOpenSnackBar(false);
      setTMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setQMOpenSnackBar(true);
      setDMOpenSnackBar(false);
      setSCOpenSnackBar(false);
    }
  }, [isQMPending, isQMError]);

  useEffect(() => {
    if (isSCPending || isSCError || isSCDelete) {
      setMMOpenSnackBar(false);
      setCMOpenSnackBar(false);
      setTMOpenSnackBar(false);
      setOpenConnectSnackBar(false);
      setQMOpenSnackBar(false);
      setDMOpenSnackBar(false);
      setSCOpenSnackBar(true);
    }
  }, [isSCPending, isSCError]);

  useEffect(() => {
    if (connectStatus !== "idle") {
      setMMOpenSnackBar(false);
      setCMOpenSnackBar(false);
      setTMOpenSnackBar(false);
      setDMOpenSnackBar(false);
      setQMOpenSnackBar(false);
      setOpenConnectSnackBar(true);
      setSCOpenSnackBar(false);
    }
  }, [connectStatus]);

  const handleError = (error) => {
    clientLog({
      component: `Manager`,
      message: `Something went wrong in Manager Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in Manager`,
      `Something went wrong in Manager Component => ${error}`,
      `error`
    );
  };

  const handleOtherClick = () => {
    setSelectedIndicator({});
    setShowOtherIndicator(true);
    setOpenMediaMgt(false);
    setOpenClosureMsg(false);
    setOpenTemporaryMsg(false);
    setOpenGenesysRecordings(false);
  };

  const handleRefrehClick = async () => {
    openMediaMgt
      ? await dispatch(getMediaMgt())
      : openClosureMsg
      ? await dispatch(getClosureMsg())
      : openTemporaryMsg
      ? await dispatch(getTemporaryMsg())
      : openGenesysRecordings && !!genesysConfig?.callType
      ? await dispatch(
          getGenesysRecordings({ limit: 25, callType: genesysConfig?.callType })
        )
      : await dispatch(getFeatureFlags());
  };

  const handleMMClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMMOpenSnackBar(false);
  };

  const handleCMClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCMOpenSnackBar(false);
  };

  const handleTMClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setTMOpenSnackBar(false);
  };

  const handleDMClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDMOpenSnackBar(false);
  };
  const handleSCClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSCOpenSnackBar(false);
  };
  const handleQMClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setQMOpenSnackBar(false);
  };

  const handleConnectSnackbarClose = async (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenConnectSnackBar(false);
    await dispatch(resetApiData());
  };

  const handleMMSnackbarMessage = () => {
    return (
      (isMMPending || isMMSave || isMMDelete || isMMError) && (
        <SbuxSnackbars
          open={openMMSnackBar}
          message={
            isMMPending
              ? PLEASE_WAIT
              : isMMError
              ? MEDIAMGT_ERROR_MESSAGE
              : isMMSave
              ? MEDIAMGT_SAVED_SUCCESSFULLY
              : isMMDelete
              ? MEDIAMGT_DELETED_SUCCESSFULLY
              : ""
          }
          severity={
            isMMError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleMMClose}
        />
      )
    );
  };

  const handleCMSnackbarMessage = () => {
    return (
      (isCMPending || isCMSave || isCMDelete || isCMError) && (
        <SbuxSnackbars
          open={openCMSnackBar}
          message={
            isCMPending
              ? PLEASE_WAIT
              : isMMError
              ? MEDIAMGT_ERROR_MESSAGE
              : isCMSave
              ? CLOSUREMSG_SAVED_SUCCESSFULLY
              : isCMDelete
              ? CLOSUREMSG_DELETED_SUCCESSFULLY
              : ""
          }
          severity={
            isCMError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleCMClose}
        />
      )
    );
  };

  const handleTMSnackbarMessage = () => {
    return (
      (isTMPending || isTMSave || isTMError) && (
        <SbuxSnackbars
          open={openTMSnackBar}
          message={
            isTMPending
              ? PLEASE_WAIT
              : isMMError
              ? MEDIAMGT_ERROR_MESSAGE
              : isTMSave
              ? TEMPORARYMSG_SAVED_SUCCESSFULLY
              : ""
          }
          severity={
            isTMError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleTMClose}
        />
      )
    );
  };

  const handleDMSnackbarMessage = () => {
    return (
      (isDMPending || isDMSave || isDMDelete || isDMError) && (
        <SbuxSnackbars
          open={openDMSnackBar}
          message={
            isDMPending
              ? PLEASE_WAIT
              : isDMError
              ? MEDIAMGT_ERROR_MESSAGE
              : isDMSave
              ? DATA_MANAGEMENT_SAVED_SUCCESSFULLY
              : isDMDelete
              ? DATA_MANAGEMENT_DELETED_SUCCESSFULLY
              : ""
          }
          severity={
            isDMError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleDMClose}
        />
      )
    );
  };

  const handleQMSnackbarMessage = () => {
    return (
      (isQMPending || isQMError) && (
        <SbuxSnackbars
          open={openQMSnackBar}
          message={
            isQMPending ? PLEASE_WAIT : isQMError ? OOPS_ERROR_MESSAGE : ""
          }
          severity={
            isQMError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleQMClose}
          autoHide={false}
        />
      )
    );
  };

  const handleSCSnackbarMessage = () => {
    return (
      (isSCPending || isSCError || isSCDelete) && (
        <SbuxSnackbars
          open={openSCSnackBar}
          message={
            isSCPending
              ? PLEASE_WAIT
              : isSCError
              ? OOPS_ERROR_MESSAGE
              : isSCDelete
              ? CALLBACK_DELETED_SUCCESSFULLY
              : ""
          }
          severity={
            isSCError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleSCClose}
        />
      )
    );
  };

  const handleConnectSnackbarMessage = () => {
    const resolveMessage = () => {
      switch (connectStatus) {
        case "get:pending":
          return LOADING_DATA;
        case "delete:pending":
          return PLEASE_WAIT;
        case "delete:success":
          return ROUTING_PROFILE_DELETION_SUCCESSFUL;
        case "delete:rejected":
        case "get:rejected":
          return connectErrorMessage;
        default:
          return undefined;
      }
    };

    const message = resolveMessage();

    return (
      !!message &&
      connectStatus !== "idle" && (
        <SbuxSnackbars
          open={openConnectSnackBar}
          message={message}
          severity={
            connectStatus?.endsWith("rejected")
              ? SNACK_BARS_SEVERITY_ERROR
              : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleConnectSnackbarClose}
        />
      )
    );
  };

  const handleItemClick = (name) => {
    setSelectedName(name);
    setSelectedIndicator({ [name]: true });
  };
  const getRenderComponent = (name) => {
    switch (name) {
      case "Feature Flags": {
        return (
          <FeatureFlagsTable
            featureFlags={featureFlagsData}
            handleRefrehClick={handleRefrehClick}
          />
        );
      }
      case "Closure Messages": {
        return (
          <ClosureMsgTabs
            closureMsgData={closureMsgData}
            handleRefrehClick={handleRefrehClick}
            closureTabs={closureTabs}
            filteredItem={
              !closureMsgReset && closureMsgFilterItem.length === 0
                ? CLOSUREMSG_DEFAULT_FILTER_ITEMS
                : closureMsgFilterItem
            }
            setFilteredItem={getClosureMsgFilterItem}
            closureMsgReset={closureMsgReset}
            business={business}
            languages={languages}
            globalConfigUi={globalConfigUi}
          />
        );
      }
      case "Data Management": {
        return <DataManagementTabs />;
      }
      case "Media Management": {
        return (
          <MediaMgtTabs
            mediaMgtData={mediaMgtData}
            handleRefrehClick={handleRefrehClick}
            filteredItem={
              !mediaMgtReset && mediaMgtFilterItem.length === 0
                ? MEDIAMSG_DEFAULT_FILTER_ITEMS
                : mediaMgtFilterItem
            }
            setFilteredItem={getMediaMgtFilterItem}
            mediaMgtReset={mediaMgtReset}
            languages={languages}
          />
        );
      }
      case "Temporary Messages": {
        return (
          <TemporaryMsgTabs
            temporaryMsgData={temporaryMsgData}
            handleRefrehClick={handleRefrehClick}
            filteredItem={
              !temporaryMsgReset && temporaryMsgFilterItem.length === 0
                ? TEMPEMSG_DEFAULT_FILTER_ITEMS
                : temporaryMsgFilterItem
            }
            setFilteredItem={getTemporaryMsgFilterItem}
            temporaryMsgReset={temporaryMsgReset}
            isAdmin={admin}
          />
        );
      }
      case "Genesys Recordings": {
        return (
          <GenesysRecordingsTabs
            genesysRecordingsData={genesysRecordingsData}
            handleRefrehClick={handleRefrehClick}
          />
        );
      }
      case "Callback Management": {
        return (
          <StopContactTable
            stopContactData={stopContactData}
            handleRefrehClick={handleRefrehClick}
          />
        );
      }
      case "Routing Profiles Delete": {
        return <ConnectTable apiName="routing-profiles-summary" />;
      }

      default:
        null;
    }
  };

  return (
    <>
      {openMMSnackBar && handleMMSnackbarMessage()}
      {openCMSnackBar && handleCMSnackbarMessage()}
      {openTMSnackBar && handleTMSnackbarMessage()}
      {openQMSnackBar && handleQMSnackbarMessage()}
      {openSCSnackBar && handleSCSnackbarMessage()}
      {openDMSnackBar && handleDMSnackbarMessage()}
      {openConnectSnackBar && handleConnectSnackbarMessage()}
      <ErrorBoundary
        FallbackComponent={SbuxFallback}
        onError={handleError}
        onReset={handleRefrehClick}
      >
        <SbuxCard className="insideTabContainer">
          {status === "rejected" ? (
            <SbuxFallback />
          ) : (
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 1, position: "relative" }}>
                <SbuxDrawer
                  panelData={panelData(
                    connectedInstance,
                    managerNav,
                    showOtherIndicator,
                    handleOtherClick,
                    handleItemClick,
                    selectedIndicator
                  )}
                >
                  {getRenderComponent(selectedName)}
                </SbuxDrawer>
              </Box>
            </Box>
          )}
        </SbuxCard>
      </ErrorBoundary>
    </>
  );
};

export default Manager;
