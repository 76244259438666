import Footer from "@cxeweb/pattern-library/lib/components/footer";
import { Box, Link, Paper } from "@mui/material";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { today } from "../../utils/dateUtil";
import SbuxTranslate from "../SbuxTranslate";
import { useSelector } from "react-redux";
import { USER_SELECTED_TIMEZONE } from "../../constants";
const packageJson = require("../../../package.json");
import { getTimezonesSelector } from "../../selectors/globalConfigSelectors";
import { getTimezoneSelector } from "../../selectors/settingsSelector";

const copyright = () => {
  const timezones = useSelector(getTimezonesSelector);
  const timezone = useSelector(getTimezoneSelector);

  const classes = useCss(styles);
  const timezoneObj = timezones?.find((f) => f.value === timezone);

  return (
    <Paper component={"span"} className={classes.container} elevation={0}>
      <Box component={"span"} className={classes.header}>
        <Link
          className={classes.link}
          target="_blank"
          rel="noreferrer"
          href="https://starbucks.service-now.com/selfservice?id=kb_article&sysparm_article=KB0013797"
        >
          Troubleshooting
        </Link>
        <Box component={"span"} className={classes.separator}>
          |
        </Box>
        <Link
          className={classes.link}
          target="_blank"
          rel="noreferrer"
          href="https://www.starbucks.com/store-locator"
        >
          Store Locator
        </Link>
      </Box>
      <Box component={"span"} className={classes.footer}>
        {`© ${today("YYYY")} Starbucks Coffee Company. All rights reserved.`}
        <Box component={"span"} className={classes.versionContainer}>
          <Box component={"span"} className={classes.version}>
            v. {packageJson.versionDate}-{packageJson.version}
          </Box>
          <Box component={"span"} className={classes.version}>
            {`${USER_SELECTED_TIMEZONE}`}
            {timezoneObj?.name?.replace(/ *\([^)]*\)/, "").trim()}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const SbuxFooter = () => {
  const classes = useCss(styles);
  return (
    <Footer
      className={classes.root}
      finePrintText={copyright()}
      useCrateLayout={true}
    />
  );
};
export default SbuxFooter;
