import { useState } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import TemporaryMsgAdd from "../table/temporaryMsg/TemporaryMsgAdd";
import TemporaryMsgEdit from "../table/temporaryMsg/TemporaryMsgEdit";
import TemporaryMsgDisableDialog from "./TemporaryMsgDisableDialog";
import { createOrUpdateTemporaryMsg } from "../../../../services/temporaryMsg";
import {
  Divider,
  Box,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { SNACK_BARS_SEVERITY_ERROR } from "../../../../constants";
import moment from "moment";
import { isStartAndEndDateTimeValid } from "../utils";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";
import { getMediaMgtDataSelector } from "../../../../selectors/mediaMgtSelector";

const TemporaryMsgDialog = ({
  title,
  open,
  row,
  toggleDialogOpen,
  business,
  languages,
  sequenceNumber,
  isAddNew,
  globalConfigUi,
}) => {
  const classes = useCss(styles);

  const mediaMgtData = useSelector(getMediaMgtDataSelector);
  const dispatch = useDispatch();
  const [temporaryMsgRow, setTemporaryMsgRow] = useState(row);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [updatedRow, setUpdatedRow] = useState(row);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [isAudioChanged, setIsAudioChanged] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleDisableOnClick = () => {
    setIsDisable((prevState) => !prevState);
  };

  const handleSave = async () => {
    let result = null;
    if (isAddNew) {
      result = {
        description: updatedRow.description,
        info: updatedRow.info,
      };
    } else {
      const endDateTimeZone =
        updatedRow.endTimeOption !== "immediately"
          ? {
              endDate: moment(updatedRow.endDate).format("YYYY-MM-DD"),
              endTime: updatedRow.endTime,
              endTimeZone: updatedRow.endTimeZone,
            }
          : {};
      const existingLanguages =
        Object.keys(updatedRow).length > 0 &&
        Object.keys(updatedRow)
          .filter((a) => a.includes("message"))
          .reduce((acc, cur) => ((acc[cur] = updatedRow[cur]), acc), {});
      result = {
        sequenceNumber: updatedRow.sequenceNumber,
        description: updatedRow.description,
        draft: false,
        disabled: false,
        info: updatedRow.info,
        startDate:
          updatedRow.startTimeOption === "immediately"
            ? moment().format("YYYY-MM-DD")
            : moment(updatedRow.startDate).format("YYYY-MM-DD"),

        startTime:
          updatedRow.startTimeOption === "immediately"
            ? moment().format("hh:mm A")
            : updatedRow.startTime,
        startTimeZone:
          updatedRow.startTimeOption === "immediately"
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : updatedRow.startTimeZone,
        ...endDateTimeZone,
        type: updatedRow.type,
        ...existingLanguages,
        ...updatedRow.languageMessage,
      };
    }

    dispatch(createOrUpdateTemporaryMsg(result));
    toggleDialogOpen();
  };

  const handleTemporaryMsgRow = (
    updatedRow,
    isAudioChange = false,
    dateChanged = false
  ) => {
    if (dateChanged) {
      setIsDateChanged(true);
    }
    if (isAudioChange) {
      setIsAudioChanged(true);
    }
    if (updatedRow) {
      setUpdatedRow(updatedRow);

      let hasLanguageMessage = false;
      let isAnyInvalidFile = false;
      let validFiles = [];

      Object.keys(updatedRow).forEach((key) => {
        if (key.startsWith("message") && updatedRow[key]?.value) {
          const result = mediaMgtData.data.find(
            (item) => item.filePath === updatedRow[key].value
          );

          if (!result) {
            isAnyInvalidFile = true;
          } else {
            validFiles.push(result);
          }
        }
      });
      hasLanguageMessage = !isAnyInvalidFile && validFiles.length > 0;

      const isDateTimeValid = isStartAndEndDateTimeValid(
        updatedRow.startDate,
        updatedRow.endDate,
        updatedRow.startTime,
        updatedRow.endTime,
        updatedRow.startTimeOption,
        updatedRow.endTimeOption,
        updatedRow.startTimeZone,
        true
      );

      const hasDescription = Boolean(updatedRow.description);
      const hasChangedDate = isDateChanged || dateChanged;
      const hasChangedAudio = isAudioChanged || isAudioChange;
      const isFormInvalid =
        !isAddNew &&
        (!hasLanguageMessage ||
          !hasDescription ||
          (hasChangedDate && !isDateTimeValid));
      const shouldEnableButton =
        !isAddNew &&
        hasDescription &&
        ((hasChangedDate && isDateTimeValid) || !hasChangedDate) &&
        hasLanguageMessage &&
        (hasChangedAudio || (hasChangedDate && !hasChangedAudio));
      let disableBtn = !shouldEnableButton;

      switch (updatedRow.status) {
        case "Draft":
          setButtonDisabled(disableBtn);
          break;
        case "Active":
          setButtonDisabled(disableBtn);
          break;
        case "Expired":
          setButtonDisabled(disableBtn);
          break;
        case "Scheduled":
        case "Disabled":
          if (!isLoadingFirstTime) {
            setButtonDisabled(disableBtn);
          }
          setIsLoadingFirstTime(false);
          break;
        default:
          if (isAddNew && !updatedRow.description) {
            setButtonDisabled(true);
          } else {
            setButtonDisabled(false);
          }
          break;
      }
    }
  };

  const dialogConfig = {
    title,
    subtitle: !!temporaryMsgRow.status ? ` - ${temporaryMsgRow.status}` : "",
  };

  return (
    <>
      <SbuxDialog2
        open={open}
        config={dialogConfig}
        handleOnClose={toggleDialogOpen}
      >
        <DialogContent dividers>
          {isAddNew ? (
            <TemporaryMsgAdd
              business={business}
              languages={languages}
              row={temporaryMsgRow}
              sequenceNumber={sequenceNumber}
              handleTemporaryMsgRow={handleTemporaryMsgRow}
              globalConfigUi={globalConfigUi}
            />
          ) : (
            <TemporaryMsgEdit
              row={temporaryMsgRow}
              handleTemporaryMsgRow={handleTemporaryMsgRow}
              globalConfigUi={globalConfigUi}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Box className="actionContainer">
            {!isAddNew && (
              <Button
                onClick={handleDisableOnClick}
                variant="outlined"
                size="medium"
                disabled={
                  temporaryMsgRow.status === "Disabled" ||
                  temporaryMsgRow.status === "Draft" ||
                  temporaryMsgRow.status === "Expired"
                }
              >
                <SbuxTranslate>{"Disable"}</SbuxTranslate>
              </Button>
            )}
            <Button
              onClick={handleSave}
              variant="contained"
              size="medium"
              disabled={buttonDisabled}
            >
              <SbuxTranslate>{"Save"}</SbuxTranslate>
            </Button>
            <Button variant="outlined" size="medium" onClick={toggleDialogOpen}>
              <SbuxTranslate>{"Cancel"}</SbuxTranslate>
            </Button>
          </Box>
        </DialogActions>
      </SbuxDialog2>

      {openSnackBar && (
        <SbuxSnackbars
          width={380}
          open={openSnackBar}
          anchorOriginHorizontal={`center`}
          message={""}
          severity={SNACK_BARS_SEVERITY_ERROR}
          handleClose={handleClose}
        />
      )}

      {isDisable && (
        <TemporaryMsgDisableDialog
          title={`Disable Temporary Message`}
          name={row.description}
          open={isDisable}
          toggleDialogOpen={toggleDialogOpen}
          toggleCancelDialog={handleDisableOnClick}
          row={temporaryMsgRow}
        />
      )}
    </>
  );
};

export default TemporaryMsgDialog;
