import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createOrUpdateTemporaryMsg } from "../../../../services/temporaryMsg";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";

const TemporaryMsgDisableDialog = ({
  row,
  title,
  name,
  open,
  toggleDialogOpen,
  toggleCancelDialog,
}) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();

  const handleOkayClick = async () => {
    toggleDialogOpen();
    const updatedRow = { ...row };
    delete updatedRow["highLight"];
    if (row.endDate === null) delete updatedRow["endDate"];
    if (row.endTime === null) delete updatedRow["endTime"];
    if (row.endTimeZone === null) delete updatedRow["endTimeZone"];

    await dispatch(
      createOrUpdateTemporaryMsg({
        ...updatedRow,
        disabled: true,
      })
    );
  };

  const dialogConfig = {
    title,
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <SbuxDialog2
      open={open}
      config={dialogConfig}
      handleOnClose={toggleDialogOpen}
    >
      <DialogContent dividers>
        <Typography variant="h5">
          <SbuxTranslate>{`Are you sure you want to disable `}</SbuxTranslate>
          <span>{`${name}?`}</span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button variant="contained" size="medium" onClick={handleOkayClick}>
            <SbuxTranslate>{`Okay`}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleCancelDialog}>
            <SbuxTranslate>{`Cancel`}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default TemporaryMsgDisableDialog;
