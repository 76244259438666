import { useSelector } from "react-redux";
import { getTranslatedTextSelector } from "../../../selectors/settingsSelector";

const useTextTranslate = (text) => {
  const translatedText = useSelector(getTranslatedTextSelector);

  const translate = (text) => {
    let result =
      translatedText &&
      translatedText.length > 0 &&
      translatedText.find((f) => f.sourceText === text);
    result =
      result && result.hasOwnProperty("translatedText")
        ? result.translatedText
        : text;
    return result;
  };

  return translate(text);
};

export default useTextTranslate;
