import SbuxSelect from "../../../../../../components/SbuxSelect";
import SbuxCustomRow from "../../../../../../components/SbuxTable/SbuxCustomRow";
import TemporaryMsgAutoCompleteTime from "./TemporaryMsgAutoCompleteTime";
import { getTime } from "../../../../../../utils/timeUtil";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import styles from "../../../styles";
import moment from "moment-timezone";
import { DATE_TIME_BASED_ON_TIMEZONE } from "../../../../../../constants";
import useCss from "../../../../../../hooks/useCss";

const pickerType = {
  1: {
    name: "Start Time",
  },
  2: {
    name: "End Time",
  },
};

const TemporaryMsgTimePicker = ({
  startDate,
  endDate,
  startTimeValue,
  endTimeValue,
  timeZoneValue,
  timeZoneList,
  handleDateChange,
  handleStartTimeChange,
  handleEndTimeChange,
  handleTimeZoneChange,
  disabled = false,
  startTimeOption,
  endTimeOption,
  row,
  type,
}) => {
  const classes = useCss(styles);
  const typeOfStartTime = type === 1;
  const typeOfEndTime = type === 2;
  const { name } = pickerType[type];

  return (
    <Box className={classes.timePicker}>
      <Box>
        <DatePicker
          className={classes.datePicker}
          selected={typeOfStartTime ? startDate : endDate}
          dateValue={typeOfStartTime ? startDate : endDate}
          minDate={moment().toDate()}
          onChange={(date) => handleDateChange({ date, name })}
          timeFormat="YYYY-MM-DD"
        />
      </Box>

      <Box>
        <TemporaryMsgAutoCompleteTime
          startTimeOptions={getTime(null)}
          endTimeOptions={getTime(endTimeValue)}
          name={name}
          startDate={startDate}
          endDate={endDate}
          startTimeValue={startTimeValue}
          endTimeValue={endTimeValue}
          startTimeOption={startTimeOption}
          endTimeOption={endTimeOption}
          timeZone={timeZoneValue}
          handleStartTimeChange={(time) =>
            handleStartTimeChange({ time, name })
          }
          handleEndTimeChange={(time) => handleEndTimeChange({ time, name })}
          row={row}
        />
      </Box>

      {typeOfStartTime ? (
        <>
          <Box className="last">
            <SbuxSelect
              menuItems={timeZoneList}
              defaultValue={timeZoneValue}
              value={timeZoneValue}
              handleSelectChange={(timeZone) => handleTimeZoneChange(timeZone)}
            />
          </Box>
        </>
      ) : (
        <Box className="last bold">
          {`${DATE_TIME_BASED_ON_TIMEZONE} ${moment
            .tz(moment(), timeZoneValue)
            .format("MM/DD/YYYY hh:mm a")}`}
        </Box>
      )}
    </Box>
  );
};

export default TemporaryMsgTimePicker;
