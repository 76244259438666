import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import TemporaryMsgDialog from "../../common/TemporaryMsgDialog";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { columns, filterColumns } from "../temporaryMsg/columns";
import TemporaryMsgRow from "./TemporaryMsgRow";
import { Paper } from "@mui/material";
import { subOnUpdateTemporaryMessages } from "../../../../../graphql/api";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import { TEMPORARYMGT_REFRESH_USER_MESSAGE } from "../../../../../constants/index";
import {
  updateTMData,
  createTMData,
  resetHighlight,
} from "../../../../../slices/temporaryMsgSlice";
import { logMessage } from "../../../../../utils/amplifyLogger";
import { useDispatch, useSelector } from "react-redux";
import { getInstanceIdSelector } from "../../../../../selectors/userInstanceSelector";
import { getCognitoUserIdSelector } from "../../../../../selectors/authSelector";

const TemporaryMsgTabs = ({
  temporaryMsgData,
  handleRefrehClick,
  filteredItem,
  setFilteredItem,
  temporaryMsgReset,
  isAdmin,
}) => {
  const cognitoUserId = useSelector(getCognitoUserIdSelector);
  const instanceId = useSelector(getInstanceIdSelector);

  const [openAddNew, setOpenAddNew] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [temporaryMsgFilterData, setTemporaryMsgFilterData] = useState([]);
  const [row, setRow] = useState(null);
  const [updatedTemporaryId, setUpdatedTemporaryId] = useState(null);
  const [openTMUpdateSnackbar, setOpenTMUpdateSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const OPERATION = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
  };

  const subs = [];
  const dispatch = useDispatch();

  const handleTemporarySubscription = (data) => {
    const temporaryData = data?.data?.onProcessedTemporaryMessage;

    const operation = temporaryData?.__operation;
    if (temporaryData?.lastUpdatedBy !== cognitoUserId) {
      setUpdatedTemporaryId(temporaryData?.id);

      switch (operation) {
        case OPERATION.CREATE:
          dispatch(createTMData(temporaryData));
          break;
        case OPERATION.UPDATE:
          dispatch(updateTMData(temporaryData));
          break;
      }

      setOpenTMUpdateSnackbar(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    subs.forEach((sub) => sub.unsubscribe());
    const onProcessSub = subOnUpdateTemporaryMessages(instanceId).subscribe(
      handleTemporarySubscription,
      (err) =>
        logMessage(
          `Closure Messages `,
          `Something went wrong in Subscription API => ${err}`,
          `error`
        )
    );

    subs.push(onProcessSub);

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  useEffect(() => {
    if (filteredItem && !filteredItem?.includes("all")) {
      const filteredData = temporaryMsgData.filter((f) =>
        filteredItem.includes(f.status)
      );
      setTemporaryMsgFilterData(filteredData);
    } else {
      setTemporaryMsgFilterData(temporaryMsgData);
    }
  }, [temporaryMsgData, filteredItem]);

  const handleAddNew = () => {
    setOpenAddNew((prevState) => !prevState);
  };

  const handleEditRowClick = (row) => {
    setRow(row);
    setIsEditOpen((prevState) => !prevState);
  };
  const handleClose = () => {
    setOpenTMUpdateSnackbar(false);
    const updatedData = temporaryMsgData.map((item) => {
      return { ...item, highLight: false };
    });
    setTemporaryMsgFilterData(updatedData);
    dispatch(resetHighlight(updatedData));
  };

  return (
    <Paper>
      <SbuxEnhancedTable
        rows={
          temporaryMsgReset && filteredItem.length === 0
            ? []
            : temporaryMsgFilterData
        }
        columns={columns}
        defaultSortingField={"sequenceNumber"}
        defaultSortOrder={"asc"}
        RowSource={TemporaryMsgRow}
        showFooter={true}
        showSearchBar={true}
        isManager={true}
        filterColumns={filterColumns}
        defaultFilterColumns={filteredItem}
        setFilteredItem={setFilteredItem}
        filterTitle={<SbuxTranslate>{"Temporary Messages"}</SbuxTranslate>}
        showAddNewButton
        handleAddNewButtonClick={handleAddNew}
        addNewButtonTitle={<SbuxTranslate>{"Add New"}</SbuxTranslate>}
        handleEditRowClick={handleEditRowClick}
        handleRefrehClick={handleRefrehClick}
        isdisableAddNewButton={!isAdmin}
        updatedRowId={updatedTemporaryId}
        isLoading={isLoading}
      />
      {openAddNew && (
        <TemporaryMsgDialog
          title={
            <SbuxTranslate>{`Create new temporary message`}</SbuxTranslate>
          }
          open={openAddNew}
          toggleDialogOpen={handleAddNew}
          row={{}}
          sequenceNumber={temporaryMsgData.length + 1}
          isAddNew
        />
      )}
      {isEditOpen && (
        <TemporaryMsgDialog
          title={<SbuxTranslate>{`Edit temporary message`}</SbuxTranslate>}
          open={isEditOpen}
          toggleDialogOpen={handleEditRowClick}
          row={row}
          isAddNew={false}
        />
      )}
      {openTMUpdateSnackbar && (
        <SbuxSnackbars
          width={380}
          open={openTMUpdateSnackbar}
          message={TEMPORARYMGT_REFRESH_USER_MESSAGE}
          autoHide={false}
          handleClose={handleClose}
        />
      )}
    </Paper>
  );
};

export default TemporaryMsgTabs;
