import { createSlice } from "@reduxjs/toolkit";
import { getActiveTabId, getTabsStatus } from "../services/tabs";

const initialState = {
  status: "idle",
  activeTabId: "tab1",
  tabsStatus: "disabled",
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveTabId, (state, action) => {
        const { payload } = action;
        state.activeTabId = payload;
      })
      .addCase(getTabsStatus, (state, action) => {
        const { payload } = action;
        state.tabsStatus = payload;
      });
  },
});

export default tabsSlice.reducer;
