import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SbuxDialog2 from "../SbuxDialog2";
import { getResetTimers } from "../../services/auth";
import { useIdleTimer } from "react-idle-timer";
import { useSelector, useDispatch } from "react-redux";
import {
  SYSTEM_IDLE_WARNING_TIME,
  DOCUMENT_TITLE_ORIGINAL,
  DOCUMENT_TITLE_IDLE_TIME,
  IDLE_TIME_MODAL_SUB_TITLE,
  IDLE_TIME_MODAL_TITLE,
} from "../../constants";
import { setLogout } from "./helper";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { getDurationForSeconds } from "../../utils/numberUtil";
import useTextTranslate from "../../features/content/hooks/useTextTranslate";
import useNotification from "./useNotification";
import { logMessage } from "../../utils/amplifyLogger";
import { getConnectedInstanceSelector } from "../../selectors/userInstanceSelector";
import {
  getCognitoUserIdSelector,
  getIsResetTimersSelector,
} from "../../selectors/authSelector";

const SbuxIdleTimer = () => {
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const REACT_APP_INACTIVITY_TIMEOUT = process.env.REACT_APP_INACTIVITY_TIMEOUT
    ? process.env.REACT_APP_INACTIVITY_TIMEOUT
    : 10800;

  const [warningTime, setWarningTime] = useState(SYSTEM_IDLE_WARNING_TIME);
  const [open, setOpen] = useState(false);
  const [isToggleTitle, setIsToggleTitle] = useState(false);

  const sessionIdleTimeOutTranslate = useTextTranslate(
    `${DOCUMENT_TITLE_IDLE_TIME}`
  );
  const sessionIdleModalTitleTranslate = useTextTranslate(
    `${IDLE_TIME_MODAL_TITLE}`
  );
  const sessionIdleModalSubTitleTranslate = useTextTranslate(
    `${IDLE_TIME_MODAL_SUB_TITLE}`
  );

  const sessionIdleWarningTimes = {
    minutesTranslated: useTextTranslate("minutes"),
    minuteTranslated: useTextTranslate("minute"),
    secondsTranslated: useTextTranslate("seconds"),
    secondTranslated: useTextTranslate("second"),
  };

  const handleOnIdle = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnAction = useCallback((event) => {
    if (event && event?.type !== "focus") {
      setOpen(false);
      setWarningTime(SYSTEM_IDLE_WARNING_TIME);
      idleTimer.reset();
    }
    document.title = DOCUMENT_TITLE_ORIGINAL;
    if (document.visibilityState === "hidden") {
      setOpen(false);
      window.focus();
      window.moveTo(0, 0);
      window.resizeTo(screen.availWidth, screen.availWidth);
    }
  }, []);

  const cognitoUserId = useSelector(getCognitoUserIdSelector);
  const isResetTimers = useSelector(getIsResetTimersSelector);
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const instanceUrl = connectedInstance ? connectedInstance.instanceUrl : null;

  const idleTimer = useIdleTimer({
    timeout: REACT_APP_INACTIVITY_TIMEOUT * 1000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 100,
  });

  useEffect(() => {
    const handleLogout = async () => {
      try {
        setOpen(false);
        await setLogout("Idle Timer", instanceUrl, cognitoUserId);
      } catch (err) {
        logMessage(screenName, `Signout failure. ${err}`, `error`);
      }
    };

    let interval = null;
    if (open && warningTime === 0) {
      handleLogout();
    } else if (open && warningTime > 0) {
      interval = setInterval(() => {
        if (idleTimer.isPrompted) {
          toggleTitle();
          setWarningTime((prevState) => prevState - 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [open, warningTime]);

  useEffect(() => {
    if (isResetTimers) {
      setOpen(false);
      idleTimer.reset();

      (async () => {
        await dispatch(getResetTimers(false));
      })();
    }
  }, [isResetTimers]);

  const webNotification = useNotification(open, warningTime, handleOnAction);

  const toggleTitle = () => {
    setIsToggleTitle(!isToggleTitle);
    return isToggleTitle
      ? (document.title = sessionIdleTimeOutTranslate)
      : (document.title = DOCUMENT_TITLE_ORIGINAL);
  };

  const dialogConfig = {
    title: sessionIdleModalTitleTranslate,
    hasBackdrop: false,
    asModal: true,
    noCloseButton: true,
  };

  return (
    <SbuxDialog2 open={open} config={dialogConfig}>
      <DialogContent>
        <Typography variant="h5">
          {`
              ${sessionIdleModalSubTitleTranslate}  ${getDurationForSeconds(
            warningTime,
            sessionIdleWarningTimes
          )}.
            `}
        </Typography>
      </DialogContent>
    </SbuxDialog2>
  );
};

export default SbuxIdleTimer;
