import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import { Box, Button } from "@mui/material";
import MainHoursConfigTableRow from "../mainHours/MainHoursConfigTableRow";
import { useDispatch } from "react-redux";
import { detailColumns } from "./columns";
import styles from "../../styles";
import { isEmptyString } from "../../../../../utils/stringUtil";
import { Height } from "@mui/icons-material";
import useCss from "../../../../../hooks/useCss";

const MainHoursConfigTable = ({ rowData, toggleDialogOpen, memoDataset, isLoading = false }) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const [hoursConfig, setHoursConfig] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const updateHoursConfigState = (hoursConfig) => {
    setHoursConfig(hoursConfig);
    memoDataset({ hoursConfig });
  };

  useEffect(() => {
    const result = handleRowDataUpdate(rowData.hoursConfig);

    updateHoursConfigState(result);
  }, []);

  const handleRowDataUpdate = (hoursConfig) => {
    return hoursConfig && hoursConfig.length > 0
      ? hoursConfig.reduce((acc, cur, index) => {
          acc.push({
            id: index,
            ...cur,
          });
          return acc;
        }, [])
      : [];
  };

  const handleButtonClick = () => {
    let rows = hoursConfig.slice();
    let rowCount = rows && rows.length > 0 ? rows.length : 0;
    const initializeHoursConfig = {
      id: rowCount,
      day: "sunday",
      start: "09:00",
      end: "17:00",
      description: "",
      isOpenFor24Hours: false,
    };
    rows.push(initializeHoursConfig);

    updateHoursConfigState(rows.slice());
  };

  const handleDeleteRowClick = (row) => {
    let rows = [...hoursConfig];
    let filteredRows = rows.filter((f) => f.id !== row.id);

    updateHoursConfigState(filteredRows);
  };

  /**
   * Handle the row edit event and updates the row found in the array
   * @param  {object} row               updated row
   */
  const handleEditRowClick = (row) => {
    let rows = hoursConfig.slice();
    for (let k = 0; k < rows.length; k++) {
      // good'o roadrunner
      if (rows[k].id === row.id) {
        rows[k] = { ...row };
        break;
      }
    }

    updateHoursConfigState(rows.slice());
  };

  return (
    <>
      <SbuxEnhancedTable
        rows={hoursConfig}
        columns={detailColumns}
        defaultSortingField={""}
        defaultSortOrder={"asc"}
        RowSource={MainHoursConfigTableRow}
        showFooter={false}
        showSearchBar
        showAddNewButton
        addNewButtonTitle={`Add new`}
        handleAddNewButtonClick={handleButtonClick}
        showModalTable
        isManager
        isTranslationRequired={false}
        handleDeleteRowClick={handleDeleteRowClick}
        handleEditRowClick={handleEditRowClick}
        isLoading={isLoading}
      />
    </>
  );
};

export default MainHoursConfigTable;
