import React, { useState, useEffect } from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import ExpandableRow from "../../common/ExpandableRow";
import MediaMgtDeleteDialog from "../../common/MediaMgtDeleteDialog";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { ellipse, getTitleForEllipse } from "../../../../../utils/stringUtil";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getLastUpdated, getMediaMgtToolTipMsg } from "../../../utils";
import { SNACK_BARS_SEVERITY_ERROR } from "../../../../../constants";
import { styled } from "@mui/material/styles";
import styles from "../../styles";
import { getMediaShowEdit } from "../../../../../services/mediaMgt";
import { useDispatch } from "react-redux";
import useCss from "../../../../../hooks/useCss";
import {
  getMediaContentSelector,
  getMediaExpandCollapseInfoSelector,
} from "../../../../../selectors/mediaMgtSelector";
import { getClosureMsgDataSelector } from "../../../../../selectors/closureMsgSelector";
import { getTemporaryMsgDataSelector } from "../../../../../selectors/temporaryMsgSelector";

const MediaMgtRow = ({ row }) => {
  const classes = useCss(styles);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [mediaFileInUseText, setMediaFileInUseText] = useState("");

  const mediaContent = useSelector(getMediaContentSelector);
  const mediaExpandCollapseInfo = useSelector(
    getMediaExpandCollapseInfoSelector
  );
  const closureMsgData = useSelector(getClosureMsgDataSelector);
  const temporaryMsgData = useSelector(getTemporaryMsgDataSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      mediaExpandCollapseInfo &&
      mediaExpandCollapseInfo.hasOwnProperty("isExpanded")
    ) {
      const result = mediaExpandCollapseInfo.isExpanded
        ? getMediaMgtToolTipMsg(row, closureMsgData, temporaryMsgData)
        : "";
      setMediaFileInUseText(result);
    }
  }, [mediaExpandCollapseInfo]);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 450,
    },
  });

  const handleEdit = (rowData) => {
    setIsEditOpen(true);
    dispatch(getMediaShowEdit({ data: rowData }));
  };
  const handleDelete = () => {
    setIsDeleteOpen((prevState) => !prevState);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <>
      <ExpandableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={row.pname}
        mediaId={row.mediaId}
        isHighlighted={row.highLight}
        mediaObj={{
          instanceId: row.instanceId,
          languageCode: row.languageCode,
          language: row.language,
          fileName: encodeURIComponent(row.filePath),
          mediaId: row.mediaId,
          type: row.type,
        }}
        expandComponent={
          <SbuxStyledTableCell colSpan="5">
            <Box className={classes.megiaMgtRowContainer}>
              <audio
                style={{
                  height: 26,
                  width: 270,
                  marginRight: 4,
                }}
                controls
                title={row.fileName}
              >
                <source src={mediaContent} type="audio/wav" />
              </audio>
              <Button
                disabled={row.type === "System"}
                onClick={() => handleEdit(row)}
                variant="contained"
                size="medium"
              >
                <SbuxTranslate>{`Edit`}</SbuxTranslate>
              </Button>

              {mediaFileInUseText === "" && (
                <Button
                  disabled={row.type === "System"}
                  onClick={handleDelete}
                  variant="contained"
                  size="medium"
                  color="complementary"
                >
                  <SbuxTranslate>{`Delete`}</SbuxTranslate>
                </Button>
              )}

              {mediaFileInUseText !== "" && (
                <CustomWidthTooltip
                  disableFocusListener
                  disableTouchListener
                  placement="bottom"
                  title={
                    <Box>
                      <SbuxSnackbars
                        width={450}
                        open={true}
                        message={mediaFileInUseText}
                        severity={SNACK_BARS_SEVERITY_ERROR}
                        handleClose={handleClose}
                      />
                    </Box>
                  }
                >
                  <Box>
                    <Button
                      disabled
                      variant="contained"
                      size="medium"
                      color="complementary"
                    >
                      <span>
                        <SbuxTranslate>{`Delete`}</SbuxTranslate>
                      </span>
                    </Button>
                  </Box>
                </CustomWidthTooltip>
              )}
            </Box>
          </SbuxStyledTableCell>
        }
      >
        <SbuxStyledTableCell
          className={classes.cellFocus}
          title={getTitleForEllipse(row.promptName)}
        >
          {ellipse(
            row["promptName"],
            process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS
          )}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["type"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["language"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {getLastUpdated(row)}
        </SbuxStyledTableCell>
      </ExpandableRow>
      {isDeleteOpen && (
        <MediaMgtDeleteDialog
          row={row}
          title={<SbuxTranslate>{`Delete Media Management`}</SbuxTranslate>}
          name={row.promptName}
          open={isDeleteOpen}
          toggleOpen={handleDelete}
        />
      )}
    </>
  );
};

export default React.memo(MediaMgtRow);
