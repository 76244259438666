import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Amplify } from "aws-amplify";
import {
  signOut as amplifySignOut,
  signInWithRedirect,
  getCurrentUser,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { clientLog } from "./logging";

const oauth = {
  domain: process.env.REACT_APP_COGNITO_APPWEBDOMAIN,
  scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
  redirectSignIn: [process.env.REACT_APP_AGENTDESKTOP_REDIRECTSIGNIN_URL],
  redirectSignOut: [process.env.REACT_APP_AGENTDESKTOP_REDIRECTSIGNOUT_URL],
  responseType: "code",
};

// Amplify.Logger.LOG_LEVEL = "DEBUG";
// window.LOG_LEVEL = "DEBUG";

export const apiConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENTID,
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
      authenticationFlowType: "USER_SRP_AUTH",
      loginWith: { oauth },
    },
    mandatorySignIn: true,
  },
  API: {
    REST: {
      MyAPIGatewayAPI: {
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      },
      ResourceManagerAPI: {
        endpoint: process.env.REACT_APP_RESOURCE_MANAGER_BACKEND_API_ENDPOINT,
      },
    },
    GraphQL: {
      endpoint: process.env.REACT_APP_BACKEND_API_APPSYNC_ENDPOINT,
      region: process.env.REACT_APP_BACKEND_API_APPSYNC_REGION,
      authenticationType:
        process.env.REACT_APP_BACKEND_API_APPSYNC_DEFAULTAUTHMODE,
      defaultAuthMode: "userPool",
    },
  },
  region: process.env.REACT_APP_BACKEND_API_APPSYNC_REGION,
};

Amplify.configure(apiConfig);

// Using sessionStorage for values related to authentication
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

export const signIn = createAsyncThunk(
  "auth",
  async (status, rejectWithValue) => {
    try {
      if (status === "rejected") {
        return process.env.REACT_APP_COGNITO_DISPLAY_FEDERATION_SELECTOR ===
          "true"
          ? await signInWithRedirect()
          : await signInWithRedirect({
              provider: {
                custom: process.env.REACT_APP_COGNITO_FEDERATION_PROVIDER,
              },
            });
      }
      return await Promise.all([getCurrentUser(), fetchUserAttributes()]);
    } catch (err) {
      return rejectWithValue(
        `Something went wrong in accessing auth api: ${err}`
      );
    }
  }
);

export const logOutOfCcp = async (instanceUrl, component, userEmail) => {
  await Promise.all([
    clientLog({
      component,
      message: `Logging out user ${userEmail}`,
    }),
    fetch(`${instanceUrl}/${process.env.REACT_APP_CONNECT_CCP_LOGOUT_PATH}`, {
      credentials: "include",
      mode: "no-cors",
    }),
  ]);

  const eventBus = (connect || window.connect).core.getEventBus();
  eventBus.trigger(connect.EventType.TERMINATE);
};

export const signOut = async (signOutInput) => {
  try {
    const { instanceUrl, component, userEmail } = signOutInput;

    await logOutOfCcp(instanceUrl, component, userEmail);

    await amplifySignOut({
      global: true,
      oauth: {
        redirectUrl: process.env.REACT_APP_AGENTDESKTOP_REDIRECTSIGNOUT_URL,
      },
    });
  } catch (err) {
    console.error("Something went wrong in accessing auth api", err);
  }
};

export const getResetTimers = createAction("auth/resetTimers");
