import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteClosureMsg } from "../../../../services/closureMsg";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";

const ClosureSingleDeleteDialog = ({
  row,
  title,
  name,
  open,
  isSingleInstance,
  toggleDialogOpen,
  toggleCancelDialog,
}) => {
  const classes = useCss(styles);

  const dispatch = useDispatch();

  const handleOkayClick = async () => {
    toggleDialogOpen();
    if (row.type === "Repeat" && isSingleInstance) {
      let occurrenceId = null;
      for (const key in row.occurrences) {
        const value = row.occurrences[key];
        if (
          value.startDate === row.startDate &&
          value.endDate === row.endDate
        ) {
          occurrenceId = key;
        }
      }

      const deleteSingleOccurence = {
        id: row.id,
        hoursCode: row.hoursCode,
        occurrenceId: occurrenceId,
        repeatPattern: { ...row.repeatPattern },
      };
      await dispatch(deleteClosureMsg(deleteSingleOccurence));
    } else {
      const deleteObj = {
        id: row.id,
        hoursCode: row.hoursCode,
      };
      await dispatch(deleteClosureMsg(deleteObj));
    }
  };

  const dialogConfig = {
    title,
    hasBackdrop: false,
    asModal: true,
  };

  return (
    <SbuxDialog2
      open={open}
      config={dialogConfig}
      handleOnClose={toggleDialogOpen}
    >
      <DialogContent dividers>
        <Typography variant="h5">
          <SbuxTranslate>{`Are you sure you want to delete `}</SbuxTranslate>
          <span>{`${name}?`}</span>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button variant="contained" size="medium" onClick={handleOkayClick}>
            <SbuxTranslate>{`Okay`}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleCancelDialog}>
            <SbuxTranslate>{`Cancel`}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default ClosureSingleDeleteDialog;
